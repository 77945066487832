import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { config } from '../util/config';
import { useLocalStorage } from '../util/localStorage';

export class CartProduct {
  id!: string;
  qty!: number;
  bundlePrice!: number | null;
  price!: number;
}

export class Cart {
  id!: string;
  storeId!: string;
  donation!: number;
  paymentMethod!: string;
  form!: any;
  products!: CartProduct[];
}

export class CartContextType {
  cart?: Cart;
  addProduct!: (
    storeId: string,
    productId: string,
    qty: number,
    price: number,
    bundlePrice?: number
  ) => void;
  updateProduct!: (storeId: string, productId: string, qty: number) => void;
  removeProduct!: (storeId: string, productId: string, qty: number) => void;
  updateDonation!: (donation: number) => void;
  updatePaymentMethod!: (paymentMethod: string) => void;
  updateCartData!: (data: any) => void;
  clear!: () => void;
  getSubTotal!: () => number;
  getDiscount!: () => number;
  getFee!: () => number;
  total!: number;
}

export const CartContext = createContext<CartContextType>({
  cart: null as any as Cart,
  addProduct: (
    storeId: string,
    productId: string,
    qty: number,
    price: number,
    bundlePrice?: number
  ) => {},
  updateProduct: (storeId: string, productId: string, qty: number) => {},
  removeProduct: (storeId: string, productId: string) => {},
  updateDonation: (donation: number) => {},
  updatePaymentMethod: (paymentMethod: string) => {},
  updateCartData: (data: any) => {},
  clear: () => {},
  getSubTotal: (): number => {
    return 0;
  },
  getDiscount: (): number => {
    return 0;
  },
  getFee: (): number => {
    return 0;
  },
  total: 0,
});

export const CartProvider = ({ children }: { children: any }) => {
  const [cart, setCart] = useLocalStorage('expressions_cart', null);
  const [total, setTotal] = useState(0);

  const addProduct = (
    storeId: string,
    productId: string,
    qty: number,
    price: number,
    bundlePrice?: number
  ) => {
    if (cart?.storeId === storeId) {
      const current: Cart = { ...cart };

      const product: CartProduct | undefined = current.products.find(
        (a: any) => a.id === productId
      );

      if (!product) {
        cart.products.push({
          id: productId,
          qty,
          price,
          bundlePrice,
        });
      } else {
        product.qty += qty;
      }
      setCart(current);
    } else {
      setCart({
        storeId: storeId,
        products: [
          {
            id: productId,
            qty,
            price,
            bundlePrice,
          },
        ],
      });
    }
    return cart;
  };

  const updateProduct = (storeId: string, productId: string, qty: number) => {
    if (cart?.storeId === storeId) {
      if (qty <= 0) {
        removeProduct(storeId, productId);
        return;
      }

      const current: Cart = { ...cart };

      const product: CartProduct | undefined = current.products.find(
        (a: any) => a.id === productId
      );

      if (product) {
        product.qty = qty;
      }

      setCart(current);
    }
  };

  const removeProduct = (storeId: string, productId: string) => {
    if (cart?.storeId === storeId) {
      const current: Cart = { ...cart };

      current.products = current.products.filter((a) => a.id !== productId);

      setCart(current);
    }
  };

  const clear = () => {
    setCart(null);
  };

  const getSubTotal = () => {
    if (cart?.products?.length) {
      const fullPrice = (cart.products as any[])
        .map((a) => a.price * a.qty)
        .reduce((a, b) => a + b);

      return fullPrice + (cart?.donation || 0);
    }

    return 0;
  };

  const getTotal = useCallback(() => {
    if (cart?.products?.length) {
      const totalOrdered = (cart.products as any[])
        .map((p: any) => p.qty)
        .reduce((a: number, b: number) => a + b);

      const bundledPrice = (cart.products as any[])
        .map(
          (a) =>
            (totalOrdered >= 3 && !!a.bundlePrice ? a.bundlePrice : a.price) *
            a.qty
        )
        .reduce((a, b) => a + b);

      return bundledPrice + (cart?.donation || 0);
    }

    return 0;
  }, [cart]);

  useEffect(() => {
    setTotal(parseFloat((getTotal() * (1 + config.surcharge)).toFixed(2)));
  }, [cart, getTotal]);

  const getDiscount = () => {
    return getSubTotal() - getTotal();
  };

  const getFee = () => {
    return parseFloat((getTotal() * config.surcharge).toFixed(2));
  };

  const updateDonation = (donation: number) => {
    setCart((prev: any) => {
      return { ...prev, donation };
    });
  };

  const updatePaymentMethod = (paymentMethod: string) => {
    setCart((prev: any) => {
      return { ...prev, paymentMethod };
    });
  };

  const updateCartData = (data: any) => {
    setCart((prev: any) => {
      return { ...prev, ...data };
    });
  };

  const contextValue = {
    cart,
    addProduct,
    updateProduct,
    removeProduct,
    clear,
    getSubTotal,
    getDiscount,
    getFee,
    total,
    updateDonation,
    updatePaymentMethod,
    updateCartData,
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
