import { buildProperty, buildSchema } from '@camberi/firecms';

export type Section = {
  order: number;
  type: string;
  md: string;
  image?: string;
  image_seo?: string;
  url?: string;
  linkText?: string;
  profit?: string;
  youtubeSource?: string;
  youtubeSize?: string;
};

export const sectionSchema = buildSchema<Section>({
  name: 'Section',
  properties: {
    order: {
      title: 'Order',
      dataType: 'number',
      validation: { required: true },
    },
    type: {
      title: 'Type',
      validation: { required: true },
      dataType: 'string',
      description: 'Section type',
      config: {
        enumValues: {
          normal: 'Normal',
          hero: 'Hero',
          article: 'Article',
          youtube: 'YouTube',
        },
      },
    },
    md: ({ values }) =>
      values.type !== 'youtube'
        ? buildProperty({
            title: 'Home Page',
            description: 'Section content',
            dataType: 'string',
            columnWidth: 300,
            validation: { required: values.type !== 'youtube' },
            config: {
              markdown: true,
            },
          })
        : null,
    image: ({ values }) =>
      values.type !== 'youtube'
        ? buildProperty({
            title: 'Image',
            dataType: 'string',
            validation: { required: values.type === 'hero' },
            config: {
              storageMeta: {
                mediaType: 'image',
                storagePath: 'images',
                acceptedFiles: ['image/*'],
              },
            },
          })
        : null,
    image_seo: ({ values }) =>
      values.type !== 'youtube'
        ? buildProperty({
            title: 'Image SEO',
            dataType: 'string',
          })
        : null,
    linkText: ({ values }) =>
      values.type !== 'youtube'
        ? buildProperty({
            title: 'Link Text',
            description: 'If link is shown then use this text',
            dataType: 'string',
            columnWidth: 300,
          })
        : null,
    url: ({ values }) =>
      values.type !== 'youtube'
        ? buildProperty({
            title: 'URL',
            description: 'Optional link',
            dataType: 'string',
            columnWidth: 300,
          })
        : null,
    profit: ({ values }) =>
      values.type !== 'youtube'
        ? buildProperty({
            dataType: 'string',
            title: 'Title',
          })
        : null,
    youtubeSource: ({ values }) =>
      values.type === 'youtube'
        ? buildProperty({
            dataType: 'string',
            title: 'Youtube Link',
            validation: { required: values.type === 'youtube' },
          })
        : null,
    youtubeSize: ({ values }) =>
      values.type === 'youtube'
        ? buildProperty({
            dataType: 'string',
            title: 'Size',
            validation: { required: values.type === 'youtube' },
            config: {
              enumValues: {
                full: 'Full',
                section: 'Section Margins',
                small: 'Small',
              },
            },
          })
        : null,
  },
});
