import { CircularProgress, MenuItem, Select } from '@mui/material';
import { addDays, parse } from 'date-fns';
import { initializeApp } from 'firebase/app';
import {
  collection,
  connectFirestoreEmulator,
  getDocs,
  getFirestore,
  query,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { config } from '../../util/config';
import { Dots } from '../Dots';

const firebaseApp = initializeApp(config.firebase);
const db = getFirestore(firebaseApp);
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const FundraisingSchools = () => {
  const [stores, setStores] = useState<any[] | null>(null);

  useEffect(() => {
    const q = query(collection(db, 'public-stores'));

    getDocs(q).then((querySnapshot) => {
      const result: any[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (
          addDays(
            parse(`${data.campaignEndDate}`, 'yyyy-LL-dd', new Date()),
            1
          ) >= new Date()
        ) {
          result.push(data);
        }
      });

      setStores(
        result.sort((a, b) =>
          a.organisationName.localeCompare(b.organisationName)
        )
      );
    });
  }, []);

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>Fundraising Orders</h1>

        <p>
          Select your organisation name from the list below to place your order:
        </p>

        {stores ? (
          <>
            <div className="grid grid-cols-1 gap-4">
              <Select
                name="store"
                placeholder="Print Style"
                autoWidth
                value=""
                onChange={(e: any) => {
                  window.location.href = e.target.value;
                }}
              >
                {stores.map((store) => (
                  <MenuItem
                    key={store.id}
                    value={`fundraising-schools/${store.id}`}
                  >
                    {store.organisationName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </>
        ) : (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        )}
      </div>
    </section>
  );
};
