import { Markdown } from '@camberi/firecms';
import { HowItWorksText } from './public/HowItWorksText';
import {
  CMSButton,
  File,
  Images,
  ImagesWithText,
  ImageWithText,
  Instagram,
  YouTube,
} from './public/Page';
import { ProfitSlider } from './public/ProfitSlider';

export const Entry = ({ entry }: { entry: any }) => {
  if (!entry) {
    return <></>;
  }
  return entry.type === 'images' ? (
    <section>
      <Images images={entry.value} layout="vertical"></Images>
    </section>
  ) : entry.type === 'imagesSmaller' ? (
    <section>
      <Images images={entry.value} layout="vertical-smaller"></Images>
    </section>
  ) : entry.type === 'imagesHorizontal' ? (
    <section>
      <Images images={entry.value} layout="horizontal"></Images>
    </section>
  ) : entry.type === 'imagesStacked' ? (
    <section>
      <Images images={entry.value} layout="stacked"></Images>
    </section>
  ) : entry.type === 'imagesGrid' ? (
    <section>
      <Images images={entry.value} layout="grid"></Images>
    </section>
  ) : entry.type === 'imageWithText' ? (
    <section>
      <ImageWithText value={entry.value}></ImageWithText>
    </section>
  ) : entry.type === 'imagesWithText' ? (
    <section>
      <ImagesWithText value={entry.value}></ImagesWithText>
    </section>
  ) : entry.type === 'youtube' ? (
    <section>
      <YouTube source={entry.value} />
    </section>
  ) : entry.type === 'instagram' ? (
    <section>
      <Instagram insta={entry.value} />
    </section>
  ) : entry.type === 'file' ? (
    <section>
      <File file={entry.value} />
    </section>
  ) : entry.type === 'button' ? (
    <section>
      <CMSButton button={entry.value} />
    </section>
  ) : entry.type === 'profit' ? (
    <section>
      <ProfitSlider />
    </section>
  ) : entry.type === 'howItWorksText' ? (
    <section>
      <HowItWorksText value={entry.value} />
    </section>
  ) : (
    <section>
      <Markdown source={entry.value} />
    </section>
  );
};
