import { initializeApp } from 'firebase/app';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { emails } from '../../cms/emails';
import { useCart } from '../../context/cart';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { logError } from '../../util/log';
import { Dots } from '../Dots';

const firebaseApp = initializeApp(config.firebase);
const functions = getFunctions(firebaseApp);
if (config.env === 'dev') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const Confirmation = () => {
  const cartContext = useCart();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  let processing = useRef(false);

  useEffect(() => {
    const processOrder = async (form: any, payment_intent: string) => {
      processing.current = true;

      try {
        const rq = {
          nonce: 'test',
          cart: { ...cartContext.cart },
          parentFirstName: form.parentFirstName,
          parentLastName: form.parentLastName,
          childFirstName: form.childFirstName,
          childLastName: form.childLastName,
          classroom: form.classroom,
          phone: form.phone,
          email: form.email,
          payment_intent,
          mode: config.stripe.mode,
        };

        const fun = httpsCallable<any, string>(functions, 'buy');
        const res = await fun(rq);

        if (!res.data.includes('Error')) {
          // send email
          await sendMail({
            to: rq.email,
            email: emails.g4r09C6HpiFxdB9alSzw,
            merge: {
              id: res.data,
              parentFirstName: rq.parentFirstName,
            },
            invoice: {
              storeId: params.id,
              orderId: cartContext?.cart?.id,
            },
          }).catch(() => {
            logError(`error sending email ${rq.email}`);
          });
        } else {
          throw new Error(res.data);
        }

        cartContext.clear();
        setLoading(false);

        window.location.href = `/fundraising-schools/${params.id}/confirm`;
      } catch (err: any) {
        toast.error(err.message);
        setTimeout(() => {
          window.location.href = `/fundraising-schools/${params.id}/checkout`;
        }, 5000);
      }
    };

    const urlSearchParams = new URLSearchParams(window.location.search);

    if (!processing.current) {
      if (urlSearchParams.get('payment_intent')) {
        const form = cartContext?.cart?.form;
        if (form) {
          setLoading(true);
          processOrder(
            form,
            urlSearchParams.get('payment_intent') ?? ''
          ).finally(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  }, [cartContext, params.id]);

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        {loading ? (
          <>
            <h1>Processing Order</h1>
            <p>
              Please wait while we process your order. Do not close this window
              or refresh the page.
            </p>
          </>
        ) : (
          <>
            <h1>Thank you for your order</h1>
            <p>
              You should receive an email shortly with confirmation of your
              order.
            </p>
          </>
        )}
      </div>
    </section>
  );
};
