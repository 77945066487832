import { VerifiedUser } from '@mui/icons-material';
import { Button, Input, Snackbar } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { config } from '../../util/config';

export const Users = () => {
  const [snack, setSnack] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const dialog = useRef(null);
  const [email, setEmail] = useState('');

  const refresh = async () => {
    const functions = getFunctions();
    if (config.env === 'dev') {
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }

    const listUsers = httpsCallable(functions, 'listUsers');
    const result: any = await listUsers();

    setUsers(result.data.users);
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div style={{ padding: '1rem' }}>
      {users.map((f, idx) => (
        <div key={idx} className="p-2 flex justify-between items-center">
          <div>
            <VerifiedUser /> {f.email}
          </div>
          <div>
            <Button
              onClick={async () => {
                const auth = getAuth();
                await sendPasswordResetEmail(auth, f.email);
                setSnack('Password reset email sent');
              }}
            >
              Password Reset
            </Button>
          </div>
        </div>
      ))}

      <Button
        onClick={() => {
          if (dialog.current) {
            (dialog.current as any).showModal();
          }
        }}
      >
        Add User
      </Button>

      <Snackbar
        open={!!snack}
        onClose={() => {
          setSnack('');
        }}
        autoHideDuration={3000}
        message={snack}
      />

      <dialog ref={dialog}>
        <h2>New User</h2>
        <form>
          <div>
            <Input
              placeholder="Email Address"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value as string);
              }}
            ></Input>
          </div>
          <div className="flex">
            <Button
              onClick={async () => {
                try {
                  const functions = getFunctions();
                  if (config.env === 'dev') {
                    connectFunctionsEmulator(functions, 'localhost', 5001);
                  }

                  const createUser = httpsCallable(functions, 'createUser');
                  const result: any = await createUser({
                    email,
                  });

                  if (result?.data) {
                    setSnack('User created');

                    await refresh();

                    setEmail('');

                    if (dialog.current) {
                      (dialog.current as any).close();
                    }
                  }
                } catch {
                  setSnack('Error creating user');
                }
              }}
            >
              Submit
            </Button>
            <Button
              type="reset"
              onClick={() => {
                setEmail('');
                if (dialog.current) {
                  (dialog.current as any).close();
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </dialog>
    </div>
  );
};
