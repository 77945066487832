import { Markdown } from '@camberi/firecms';
import { Button, TextField } from '@mui/material';
import { addDays, parse } from 'date-fns';
import { initializeApp } from 'firebase/app';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useCart } from '../../context/cart';
import { config } from '../../util/config';
import { getCloudUrl } from '../../util/image';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Base64 } from '../../util/base64';
import { Dots } from '../Dots';

const firebaseApp = initializeApp(config.firebase);
const db = getFirestore(firebaseApp);
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const Store = () => {
  const params = useParams();
  const [store, setStore] = useState<any>(null);
  const cartContext = useCart();
  const [open, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');
    let tokenId = '';
    if (token) {
      try {
        const decoded = Base64.decode(token);
        const split = decoded.split(':');
        tokenId = split[0];
      } catch {}
    }

    getDoc(doc(db, `public-stores/${params.id}`))
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setStore(data);
          if (
            (data.status === 'active' &&
              addDays(
                parse(`${data.campaignEndDate}`, 'yyyy-LL-dd', new Date()),
                1
              ) >= new Date()) ||
            tokenId === params.id
          ) {
            setIsOpen(data.status !== 'approved');
          } else {
            setIsOpen(false);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.id]);

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <h1>{store?.organisationName}</h1>

      {loading && (
        <>
          <div className="mx-6 md:mx-36 my-16 relative">
            <Dots />
            <div className="relative z-10">
              <h3>Loading...</h3>
            </div>
          </div>
        </>
      )}

      {!loading && !open ? (
        <div className="mb-4">
          <p>This store has closed or has not yet opened.</p>
        </div>
      ) : (
        <>
          <div className="mb-8">
            {!!store?.specialMessage && (
              <Markdown source={store.specialMessage}></Markdown>
            )}
          </div>
          <div className="relative z-10 grid grid-cols-1 gap-4">
            {!!store?.products?.length && (
              <>
                {store.products.map((product: any, idx: number) => (
                  <div
                    key={idx}
                    className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4 border-b-2 border-b-green last:border-b-0 md:border-0"
                  >
                    <div className="mx-auto w-9/12">
                      <img
                        src={getCloudUrl(product.photoUrl)}
                        alt="product"
                        className="w-full"
                      />
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <h2>{product.description}</h2>
                        <div className="text-xl">
                          ${parseFloat(product.price).toFixed(2)}
                        </div>
                      </div>
                      {!!product?.specialMessage && (
                        <div className="mb-4">{product?.specialMessage}</div>
                      )}
                      <div className="grid grid-cols-1 w-1/2 gap-4">
                        <TextField type="number" defaultValue={'1'}></TextField>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={(e) => {
                            e.preventDefault();
                            const qty = parseInt(
                              (e.target as any).parentElement.querySelector(
                                'input'
                              )?.value || '0'
                            );
                            cartContext.addProduct(
                              store.id,
                              product.id,
                              qty,
                              parseFloat(product.price),
                              !!product.bundlePrice
                                ? parseFloat(product.bundlePrice)
                                : undefined
                            );

                            confirmAlert({
                              title: 'Item Added',
                              message:
                                'Item added to cart. Do you want to checkout now?',
                              buttons: [
                                {
                                  label: 'Yes',
                                  onClick: () => {
                                    window.location.href = `/fundraising-schools/${store.id}/checkout`;
                                  },
                                },
                                {
                                  label: 'No',
                                  onClick: () => {},
                                },
                              ],
                            });
                          }}
                        >
                          Add to Cart
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}
    </section>
  );
};
