import { Button, Checkbox, Input, Snackbar } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import { format } from 'date-fns';
import {
  collection,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import {
  connectStorageEmulator,
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
} from 'firebase/storage';
import csvDownload from 'json-to-csv-export';
import { config } from '../../util/config';

const db = getFirestore();
const storage = getStorage();

if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
}

export const Artwork = () => {
  const [snack, setSnack] = useState('');
  const [artwork, setArtwork] = useState<any[]>([]);
  const dialog = useRef(null);
  const [email, setEmail] = useState('');

  const refresh = async () => {
    const q = query(collection(db, 'artwork'), orderBy('created', 'desc'));
    const querySnapshot = await getDocs(q);

    const result = await Promise.all(
      querySnapshot.docs.map((doc) => {
        return getDownloadURL(ref(storage, `artwork/${doc.id}.zip`))
          .then((url) => {
            const data = doc.data();
            data.id = doc.id;
            data.created =
              typeof data.created.toDate === 'function'
                ? data.created.toDate()
                : new Date(data.created._seconds * 1000);

            data.url = url;

            return data;
          })
          .catch(() => {
            const data = doc.data();
            data.id = doc.id;
            data.created =
              typeof data.created.toDate === 'function'
                ? data.created.toDate()
                : new Date(data.created._seconds * 1000);

            return data;
          });
      })
    );

    setArtwork(result);
  };

  useEffect(() => {
    refresh();
  }, []);

  const exportCsv = useCallback(async () => {
    const dataToConvert = {
      data: artwork,
      filename: 'artwork_report.csv',
      delimiter: ',',
    };

    csvDownload(dataToConvert);
  }, [artwork]);

  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
        <Button variant="outlined" onClick={exportCsv}>
          Export List
        </Button>
      </div>

      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <td>Done?</td>
            <th>Name</th>
            <th>Org</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Description</th>
            <th>Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {artwork.map((f, idx) => (
            <tr key={f.id}>
              <td>
                <Checkbox
                  color="primary"
                  title="Done?"
                  defaultChecked={f.done ?? false}
                  onChange={async (e) => {
                    const target = e.target as any;
                    await updateDoc(doc(db, `artwork/${f.id}`), {
                      done: !!target.checked,
                    });
                  }}
                />
              </td>
              <td>
                {f.firstName} {f.lastName}
              </td>
              <td>{f.organisationName}</td>
              <td>{f.email}</td>
              <td>+61{f.phone}</td>
              <td>{f.description}</td>
              <td>{format(f.created, 'PPP')}</td>
              <td>
                {f.url ? (
                  <a href={f.url}>Download</a>
                ) : (
                  <>
                    <span style={{ color: 'red' }}>File Not Found</span>
                  </>
                )}
                &nbsp;
                <span
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={async (e) => {
                    e.preventDefault();
                    if (
                      window.confirm(
                        'Are you sure you wish to delete this upload?'
                      )
                    ) {
                      await deleteDoc(doc(db, 'artwork', f.id));
                      await deleteObject(
                        ref(storage, `artwork/${f.id}.zip`)
                      ).catch(() => {});
                      await refresh();
                    }
                  }}
                >
                  Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Snackbar
        open={!!snack}
        onClose={() => {
          setSnack('');
        }}
        autoHideDuration={3000}
        message={snack}
      />

      <dialog ref={dialog}>
        <h2>New User</h2>
        <form>
          <div>
            <Input
              placeholder="Email Address"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value as string);
              }}
            ></Input>
          </div>
          <div className="flex">
            <Button
              onClick={async () => {
                try {
                  const functions = getFunctions();
                  if (config.env === 'dev') {
                    connectFunctionsEmulator(functions, 'localhost', 5001);
                  }

                  const createUser = httpsCallable(functions, 'createUser');
                  const result: any = await createUser({
                    email,
                  });

                  if (result?.data) {
                    setSnack('User created');

                    await refresh();

                    setEmail('');

                    if (dialog.current) {
                      (dialog.current as any).close();
                    }
                  }
                } catch {
                  setSnack('Error creating user');
                }
              }}
            >
              Submit
            </Button>
            <Button
              type="reset"
              onClick={() => {
                setEmail('');
                if (dialog.current) {
                  (dialog.current as any).close();
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </dialog>
    </div>
  );
};
