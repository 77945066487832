import { Logtail } from '@logtail/browser';
import { config } from './config';

const logtail = config.logtail && !!window ? new Logtail(config.logtail) : null;

export const logDebug = (message: string, structured: any = null) => {
  if (logtail) {
    logtail.debug(message, structured);
  } else {
    console.debug(message, structured);
  }
};

export const logError = (message: string | Error, structured: any = null) => {
  if (logtail) {
    logtail.error(message, structured);
  } else {
    console.error(message, structured);
  }
};
