import photo from '../assets/photo.png';

export const getCloudUrl = (url: string) => {
  if (url) {
    return `https://firebasestorage.googleapis.com/v0/b/expressions-fundraising.appspot.com/o/${url
      .replace(/\//g, '%2F')
      .replace(/ /g, '%20')
      .replace(/'/g, '%27')}?alt=media`;
  }
  return photo;
};
