import { buildProperty, buildSchema, EntitySchema } from '@camberi/firecms';
import { StorageReference } from 'firebase/storage';
import { BlogEntryPreview } from '../BlogEntryPreview';

export type NewsEntry = {
  name: string;
  slug: string;
  header_image: string;
  header_image_seo?: string;
  created_at: Date;
  author: string;
  status: string;
  tags: string[];
  content: any[];
  title?: string;
  description?: string;
};

export const getNewsSchema = (files: StorageReference[]): EntitySchema<any> => {
  return buildSchema({
    name: 'News article',
    views: [
      {
        path: 'preview',
        name: 'Preview',
        builder: (props) => <BlogEntryPreview {...props} />,
      },
    ],
    properties: {
      name: buildProperty({
        title: 'Name',
        validation: { required: true },
        dataType: 'string',
      }),
      title: buildProperty({
        title: 'SEO Title',
        validation: { required: false },
        dataType: 'string',
        config: {
          multiline: true,
        },
      }),
      description: buildProperty({
        title: 'SEO Description',
        validation: { required: false },
        dataType: 'string',
      }),
      slug: {
        title: 'Slug',
        dataType: 'string',
      },
      author: buildProperty({
        title: 'Author',
        validation: { required: true },
        dataType: 'string',
      }),
      created_at: buildProperty({
        title: 'Created',
        validation: { required: true },
        dataType: 'timestamp',
      }),
      tags: buildProperty({
        title: 'Tags',
        dataType: 'array',
        of: buildProperty({
          dataType: 'string',
          title: 'Text',
        }),
      }),
      header_image: buildProperty({
        title: 'Header image',
        dataType: 'string',
        config: {
          storageMeta: {
            mediaType: 'image',
            storagePath: 'images',
            acceptedFiles: ['image/*'],
            metadata: {
              cacheControl: 'max-age=1000000',
            },
          },
        },
      }),
      header_image_seo: {
        title: 'Header Image SEO',
        dataType: 'string',
      },
      content: buildProperty(({ values }) => {
        const enumValues: any = {};
        for (const f of files) {
          enumValues[f.fullPath] = {
            label: f.name,
          };
        }

        return {
          title: 'Content',
          description:
            'Example of a complex array with multiple properties as children',
          dataType: 'array',
          oneOf: {
            typeField: 'type', // you can ommit these `typeField` and `valueField` props to use the defaults
            valueField: 'value',
            properties: {
              images: buildProperty({
                title: 'Images Vertical',
                dataType: 'array',
                of: buildProperty({
                  dataType: 'string',
                  config: {
                    storageMeta: {
                      mediaType: 'image',
                      storagePath: 'images',
                      acceptedFiles: ['image/*'],
                      metadata: {
                        cacheControl: 'max-age=1000000',
                      },
                    },
                  },
                }),
                description:
                  'This fields allows uploading multiple images at once and reordering. Displayed vertically.',
              }),
              imagesSmaller: buildProperty({
                title: 'Images Vertical (Smaller)',
                dataType: 'array',
                of: buildProperty({
                  dataType: 'string',
                  config: {
                    storageMeta: {
                      mediaType: 'image',
                      storagePath: 'images',
                      acceptedFiles: ['image/*'],
                      metadata: {
                        cacheControl: 'max-age=1000000',
                      },
                    },
                  },
                }),
                description:
                  'This fields allows uploading multiple images at once and reordering. Displayed vertically - sized smaller.',
              }),
              imagesHorizontal: buildProperty({
                title: 'Images Horizontal',
                dataType: 'array',
                of: buildProperty({
                  dataType: 'string',
                  config: {
                    storageMeta: {
                      mediaType: 'image',
                      storagePath: 'images',
                      acceptedFiles: ['image/*'],
                      metadata: {
                        cacheControl: 'max-age=1000000',
                      },
                    },
                  },
                }),
                description:
                  'This fields allows uploading multiple images at once. Displayed horizontally.',
              }),
              imagesStacked: buildProperty({
                title: 'Images Stacked',
                dataType: 'array',
                of: buildProperty({
                  dataType: 'string',
                  config: {
                    storageMeta: {
                      mediaType: 'image',
                      storagePath: 'images',
                      acceptedFiles: ['image/*'],
                      metadata: {
                        cacheControl: 'max-age=1000000',
                      },
                    },
                  },
                }),
                description:
                  'This fields allows uploading multiple images at once. Displayed stacked.',
              }),
              imagesGrid: buildProperty({
                title: 'Images Grid',
                dataType: 'array',
                of: buildProperty({
                  dataType: 'string',
                  config: {
                    storageMeta: {
                      mediaType: 'image',
                      storagePath: 'images',
                      acceptedFiles: ['image/*'],
                      metadata: {
                        cacheControl: 'max-age=1000000',
                      },
                    },
                  },
                }),
                description:
                  'This fields allows uploading multiple images at once. Displayed grid.',
              }),
              imageWithText: buildProperty({
                title: 'Image with Text',
                dataType: 'array',
                oneOf: {
                  typeField: 'type', // you can ommit these `typeField` and `valueField` props to use the defaults
                  valueField: 'value',
                  properties: {
                    images: buildProperty({
                      dataType: 'string',
                      title: 'Image',
                      config: {
                        storageMeta: {
                          mediaType: 'image',
                          storagePath: 'images',
                          acceptedFiles: ['image/*'],
                          metadata: {
                            cacheControl: 'max-age=1000000',
                          },
                        },
                      },
                    }),
                    text: buildProperty({
                      dataType: 'string',
                      title: 'Text',
                      config: {
                        markdown: true,
                      },
                    }),
                  },
                },
              }),
              text: buildProperty({
                dataType: 'string',
                title: 'Text',
                config: {
                  markdown: true,
                },
              }),
              youtube: buildProperty({
                dataType: 'string',
                title: 'YouTube',
              }),
              instagram: buildProperty({
                dataType: 'string',
                title: 'Instagram',
              }),
              file: buildProperty({
                dataType: 'map',
                properties: {
                  fileName: buildProperty({
                    dataType: 'string',
                    config: {
                      enumValues,
                    },
                    title: 'File',
                  }),
                  title: buildProperty({
                    dataType: 'string',
                    title: 'Title',
                  }),
                  openInNewWindow: buildProperty({
                    dataType: 'boolean',
                    title: 'Open In New Window',
                  }),
                },
                title: 'File',
              }),
              button: buildProperty({
                dataType: 'map',
                properties: {
                  url: buildProperty({
                    dataType: 'string',
                    title: 'URL',
                  }),
                  title: buildProperty({
                    dataType: 'string',
                    title: 'Title',
                  }),
                  openInNewWindow: buildProperty({
                    dataType: 'boolean',
                    title: 'Open In New Window',
                  }),
                },
                title: 'Button',
              }),
            },
          },
        };
      }),
      status: buildProperty(({ values }) => ({
        title: 'Status',
        validation: { required: true },
        dataType: 'string',
        columnWidth: 140,
        config: {
          enumValues: {
            published: {
              label: 'Published',
            },
            draft: 'Draft',
          },
        },
      })),
    },
    defaultValues: {
      status: 'draft',
    },
  });
};
