import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CartProvider } from '../context/cart';
import { CheckOut } from './portal/CheckOut';
import { Confirmation } from './portal/Confirmation';
import { EditStore } from './portal/EditStore';
import { FundraisingSchools } from './portal/FundraisingSchools';
import { Login } from './portal/Login';
import { OnlineOrdering } from './portal/OnlineOrdering';
import { OrderReport } from './portal/Orders';
import { Pay } from './portal/Pay';
import { Store } from './portal/Store';
import { ClearCache } from './public/ClearCache';
import { ContactUs } from './public/ContactUs';
import { FAQ } from './public/FAQ';
import { Home } from './public/Home';
import { Index } from './public/Index';
import { News } from './public/News';
import { NewsArticle } from './public/NewsArticle';
import { Page } from './public/Page';
import { PageNotFound } from './public/PageNotFound';
import { PayOnline } from './public/PayOnline';
import { SearchResults } from './public/SearchResults';
import { Testimonials } from './public/Testimonials';
import { UploadArtwork } from './public/UploadArtwork';
import { Version } from './public/Version';

export const PublicRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Index>
              <Home />
            </Index>
          }
        />
        <Route
          path="/404"
          element={
            <Index>
              <PageNotFound />
            </Index>
          }
        />
        <Route
          path="/clear-cache"
          element={
            <Index>
              <ClearCache />
            </Index>
          }
        />
        <Route
          path="/search-results"
          element={
            <Index>
              <SearchResults />
            </Index>
          }
        />
        <Route
          path="/version"
          element={
            <Index>
              <Version />
            </Index>
          }
        />
        <Route
          path="/upload-artwork"
          element={
            <Index>
              <UploadArtwork />
            </Index>
          }
        />
        <Route
          path="/faq"
          element={
            <Index>
              <FAQ />
            </Index>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Index>
              <ContactUs />
            </Index>
          }
        />
        <Route
          path="/news"
          element={
            <Index>
              <News />
            </Index>
          }
        />
        <Route
          path="/news/:slug"
          element={
            <Index>
              <NewsArticle />
            </Index>
          }
        />
        <Route
          path="/testimonials"
          element={
            <Index>
              <Testimonials />
            </Index>
          }
        />
        <Route
          path="/online-ordering"
          element={
            <Index>
              <OnlineOrdering />
            </Index>
          }
        />
        <Route
          path="/pay-online"
          element={
            <Index>
              <PayOnline />
            </Index>
          }
        />
        <Route
          path="/fundraising-schools"
          element={
            <Index>
              <FundraisingSchools />
            </Index>
          }
        />
        <Route
          path="/fundraising-schools/:id"
          element={
            <CartProvider>
              <Index>
                <Store></Store>
              </Index>
            </CartProvider>
          }
        />
        <Route
          path="/fundraising-schools/:id/checkout"
          element={
            <CartProvider>
              <Index>
                <CheckOut></CheckOut>
              </Index>
            </CartProvider>
          }
        />
        <Route
          path="/fundraising-schools/:id/pay"
          element={
            <CartProvider>
              <Index>
                <Pay></Pay>
              </Index>
            </CartProvider>
          }
        />
        <Route
          path="/fundraising-schools/:id/confirm"
          element={
            <CartProvider>
              <Index>
                <Confirmation></Confirmation>
              </Index>
            </CartProvider>
          }
        />
        <Route
          path="/fundraising-schools/:id/manage"
          element={
            <Index>
              <Login></Login>
            </Index>
          }
        />
        <Route
          path="/fundraising-schools/:id/manage/store"
          element={
            <Index>
              <EditStore></EditStore>
            </Index>
          }
        />
        <Route
          path="/fundraising-schools/:id/manage/orders"
          element={
            <Index>
              <OrderReport></OrderReport>
            </Index>
          }
        />
        <Route
          path="/:slug"
          element={
            <Index>
              <Page />
            </Index>
          }
        />
        {/* this is so nothing gets rendered on bottom of admin system */}
        <Route path="/admin" element={<></>} />
      </Routes>
    </BrowserRouter>
  );
};
