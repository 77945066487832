import { EntityCustomViewParams } from '@camberi/firecms';
import { Box, Container } from '@mui/material';
import React from 'react';
const mjml2html = require('mjml-browser');

/**
 * This is a sample view used to render the content of a page.
 * It is bound to the data that is modified in the form.
 * @constructor
 */
export function MjmlPreview({ modifiedValues }: EntityCustomViewParams) {
  return (
    <Box>
      <Container
        maxWidth={'md'}
        style={{
          alignItems: 'center',
          justifyItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {modifiedValues?.mjml && (
          <div
            dangerouslySetInnerHTML={{
              __html: mjml2html(modifiedValues?.mjml).html,
            }}
          ></div>
        )}
      </Container>
    </Box>
  );
}
