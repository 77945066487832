import { Description, FolderZip, PictureAsPdf } from '@mui/icons-material';
import { Button, Snackbar } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  connectStorageEmulator,
  deleteObject,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { config } from '../../util/config';
import { getCloudUrl } from '../../util/image';

export const Uploads = () => {
  const [snack, setSnack] = useState('');
  const [files, setFiles] = useState<string[]>([]);

  const uploader = useRef();

  const refresh = () => {
    const _files: string[] = [];

    const storage = getStorage();
    if (config.env === 'dev') {
      connectStorageEmulator(storage, 'localhost', 9199);
    }
    const listRef = ref(storage, 'uploads');
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          _files.push(itemRef.name);
          // All the items under listRef.
        });

        setFiles(_files);
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  const getIcon = (f: string) => {
    if (f.toLowerCase().endsWith('.pdf')) {
      return <PictureAsPdf />;
    } else if (f.toLowerCase().endsWith('.zip')) {
      return <FolderZip />;
    } else {
      return <Description />;
    }
  };

  return (
    <div style={{ padding: '1rem' }}>
      {files.map((f, idx) => (
        <div key={idx} className="p-2 flex justify-between items-center">
          <div className="text-ellipsis overflow-hidden">
            {getIcon(f)} {f}
          </div>
          <div className=" whitespace-nowrap">
            <Button
              onClick={async () => {
                if (navigator.clipboard) {
                  let url = getCloudUrl(`uploads/${f}`);
                  let urlencoded = encodeURIComponent(f);
                  url = url.replace(f, urlencoded);
                  await navigator.clipboard.writeText(url);
                }
              }}
            >
              Copy Link
            </Button>
            <Button
              onClick={() => {
                confirmAlert({
                  title: 'Delete File',
                  message: 'Are you sure you wish to delete this file?',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: async () => {
                        const storage = getStorage();
                        if (config.env === 'dev') {
                          connectStorageEmulator(storage, 'localhost', 9199);
                        }
                        const fileRef = ref(storage, `uploads/${f}`);
                        deleteObject(fileRef).then(() => {
                          setSnack('File deleted');
                          refresh();
                        });
                      },
                    },
                    {
                      label: 'No',
                      onClick: () => {},
                    },
                  ],
                });
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      ))}

      <div className="border-2 p-4">
        <h2 className="text-bold text-lg">Upload Files</h2>
        <label htmlFor={uploader.current}>Select files</label>
        <input ref={uploader as any} type="file" multiple />
        <Button
          onClick={() => {
            const el: HTMLInputElement = uploader.current as any;

            if (el.files?.length) {
              const promises = [];

              const storage = getStorage();
              if (config.env === 'dev') {
                connectStorageEmulator(storage, 'localhost', 9199);
              }

              for (let i = 0; i < el.files.length; i++) {
                const file = el.files[i];

                const fileRef = ref(storage, `uploads/${file.name}`);
                promises.push(uploadBytes(fileRef, file));
              }

              Promise.all(promises).then(() => {
                setSnack('All files uploaded');
                el.value = '';
                refresh();
              });
            } else {
              setSnack('Select a file or files to upload');
            }
          }}
        >
          Upload
        </Button>
      </div>

      <Snackbar
        open={!!snack}
        onClose={() => {
          setSnack('');
        }}
        autoHideDuration={3000}
        message={snack}
      />
    </div>
  );
};
