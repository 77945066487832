import { Publish as Pub } from '@mui/icons-material';
import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import { config } from '../../util/config';

export const Publish = () => {
  const [snack, setSnack] = useState('');

  const publish = async (e: any) => {
    e.preventDefault();

    var details = {
      token: config.gitlab.trigger,
      ref: config.gitlab.ref,
    } as any;

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }

    try {
      await fetch(
        `https://gitlab.com/api/v4/projects/${config.gitlab.id}/trigger/pipeline`,
        {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: formBody.join('&'),
        }
      );

      setSnack('Publishing...');
    } catch {
      setSnack('Error publishing');
    }
  };

  return (
    <>
      <div style={{ padding: '1rem' }}>
        <Button variant="contained" onClick={publish}>
          Publish <Pub></Pub>
        </Button>
        <Snackbar
          open={!!snack}
          onClose={() => {
            setSnack('');
          }}
          autoHideDuration={3000}
          message={snack}
        />
      </div>

      <div style={{ padding: '1rem' }}>Version: {config.version}</div>
    </>
  );
};
