import { useEffect, useState } from 'react';
import './Dots.scss';

export const Dots = () => {
  const ROWS = 4;
  const COLS = 9;

  const [rows, setRows] = useState<JSX.Element[][]>([]);

  useEffect(() => {
    const _rows = [];

    for (let r = 0; r < ROWS; r++) {
      const cols: JSX.Element[] = [];
      for (let c = 0; c < COLS; c++) {
        cols.push(<span className="dot"></span>);
      }
      _rows.push(cols);
    }

    setRows([..._rows]);
  }, []);

  return (
    <div className="dots">
      <div className="underlay"></div>
      {rows.map((r, idx) => (
        <div key={`row${idx}`} className="row">
          {r.map((c, cidx) => (
            <div key={`col${cidx}`} className="col">
              <span className="dot" data-row={idx}></span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
