import { buildProperty, buildSchema } from '@camberi/firecms';

export type FAQ = {
  order: number;
  question: string;
  answer: any[];
};

export const faqSchema = buildSchema<FAQ>({
  name: 'FAQ',
  properties: {
    order: {
      title: 'Order',
      dataType: 'number',
      validation: { required: true },
    },
    question: {
      title: 'Name',
      dataType: 'string',
      columnWidth: 300,
      validation: { required: true },
    },
    answer: buildProperty({
      title: 'Answer',
      validation: { required: true },
      dataType: 'array',
      oneOf: {
        typeField: 'type', // you can ommit these `typeField` and `valueField` props to use the defaults
        valueField: 'value',
        properties: {
          text: buildProperty({
            dataType: 'string',
            title: 'Text',
            config: {
              markdown: true,
            },
          }),
        },
      },
    }),
  },
});
