import SEO from 'react-seo-component';
import { news } from '../../cms/news';
import { siteoptions } from '../../cms/siteoptions';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';

export const News = () => {
  const newsArray: any[] = Object.values(news).sort(
    (a: any, b: any) => b?.created_at?._seconds - a?.created_at?._seconds
  );

  const summary = (content: any[]) => {
    for (const c of content) {
      if (!c) continue;
      
      if (c.type === 'text' && c.value) {
        return c.value
          .replace(/\(.+\)/g, '')
          .replace(/[[\]()_*]/g, '')
          .substring(0, 250);
      }
    }
    return '';
  };

  return (
    <article className="page mx-6 md:mx-36 my-16 relative">
      <SEO
        title={'News & Success Stories'}
        titleTemplate="Expressions Fundraising"
        titleSeparator={`-`}
        description={(siteoptions as any).defaultDescription || ''}
        pathname={`https://www.expressions.com.au/`}
        siteLanguage={'en'}
        siteLocale={'en-AU'}
        twitterUsername={''}
        image={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
      />

      <Dots />
      <div className="relative z-10">
        <h1>News + Stories</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {newsArray.map((n, idx) => (
            <div key={idx}>
              <div className="shadow">
                <div
                  className="rounded shadow h-64 bg-contain bg-center bg-no-repeat -mx-2"
                  style={{
                    backgroundImage: `url(${getCloudUrl(n.header_image)})`,
                  }}
                >
                  {!!n.header_image_seo ?? (
                    <img
                      style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
                      src={`url(${getCloudUrl(n.header_image)})`}
                      alt={n.header_image_seo ?? 'article image'}
                    />
                  )}
                </div>
                <div className="p-4 min-h-[18rem] flex flex-col justify-between">
                  <div>
                    <h2 className="text-lg">{n.name}</h2>
                    <p>{summary(n.content)}</p>
                  </div>
                  <a href={`/news/${n.slug}`}>Read more</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
};
