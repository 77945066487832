import { Markdown } from '@camberi/firecms';
import { Add, Remove } from '@mui/icons-material';
import { useState } from 'react';
import SEO from 'react-seo-component';
import { faq } from '../../cms/faq';
import { pages } from '../../cms/pages';
import { siteoptions } from '../../cms/siteoptions';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';

export const AccordionItem = ({ item }: { item: any }) => {
  const [expanded, setExpanded] = useState(false);

  const id = item.question.replace(/[^a-zA-Z0-9]/g, '-');

  return (
    <div id={`#${id}`}>
      <div
        className="flex justify-between cursor-pointer p-4"
        onClick={() => {
          setExpanded((current) => !current);
        }}
      >
        <span className="font-bold text-lg">{item.question}</span>
        {expanded ? <Remove></Remove> : <Add></Add>}
      </div>
      <div
        className="p-4 transition-all ease-in-out duration-150 origin-top"
        style={{
          height: expanded ? '' : '0px',
          transform: `scaleY(${expanded ? '1' : '0'})`,
          opacity: expanded ? '1' : '0',
        }}
      >
        {item.answer.map((a: any, idx: number) => (
          <Markdown key={idx} source={a.value}></Markdown>
        ))}
      </div>
    </div>
  );
};

export const FAQ = () => {
  const page = pages.faq;

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <SEO
        title={'FAQs'}
        titleTemplate="Expressions Fundraising"
        titleSeparator={`-`}
        description={(siteoptions as any).defaultDescription || ''}
        pathname={`https://www.expressions.com.au/`}
        siteLanguage={'en'}
        siteLocale={'en-AU'}
        twitterUsername={''}
        image={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
      />
      <Dots />
      <div className="relative z-10">
        <h1>FAQs</h1>

        {!!page?.header_image && (
          <img
            className="w-full mb-4"
            src={getCloudUrl(page?.header_image || '')}
            alt="hero"
          ></img>
        )}

        {faq
          .sort((a, b) => a.order - b.order)
          .map((f, idx) => (
            <AccordionItem key={idx} item={f}></AccordionItem>
          ))}
      </div>
    </section>
  );
};
