import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { PublicRouter } from './components/Public';
import { AdminRouter } from './components/Admin';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Toaster
      toastOptions={{
        duration: 5000,
      }}
    />
    <AdminRouter />
    <PublicRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
