import {
  Button,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SEO from 'react-seo-component';
import { emails } from '../../cms/emails';
import { pages } from '../../cms/pages';
import { siteoptions } from '../../cms/siteoptions';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';
import { Entry } from '../Entry';

export const ContactUs = () => {
  const page = pages['contact-us'];
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState<any>({});

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if ((form?.whatWouldYouLikeToDo ?? 'select') === 'select') {
      setLoading(false);
      toast.error(`Please select what you would like to do`);
      return;
    }

    let content = '';

    console.log(form);

    if (form?.whatWouldYouLikeToDo === 'Request a call back') {
      content = `<table>
      <tr><th>First Name</th><td>${form.firstName || ''}</td></tr>
      <tr><th>Last Name</th><td>${form.lastName || ''}</td></tr>
      <tr><th>What would you like to do?</th><td>${
        form.whatWouldYouLikeToDo || ''
      }</td></tr>
      <tr><th>Phone Number</th><td>${form.phone || ''}</td></tr>
    </table>`;
    } else if (form?.whatWouldYouLikeToDo === 'Other') {
      content = `<table>
      <tr><th>First Name</th><td>${form.firstName || ''}</td></tr>
      <tr><th>Last Name</th><td>${form.lastName || ''}</td></tr>
      <tr><th>Email</th><td>${form.email || ''}</td></tr>
      <tr><th>What would you like to do?</th><td>${
        form.whatWouldYouLikeToDo || ''
      }</td></tr>
      <tr><th>Message</th><td>${form.message || ''}</td></tr>
    </table>`;
    } else {
      if ((form?.organisationType ?? 'select') === 'select') {
        setLoading(false);
        toast.error('Please select your organisation type');
        return;
      }
      if ((form?.howDidYouHear ?? 'select') === 'select') {
        setLoading(false);
        toast.error('Please select how you heard about us');
        return;
      }
      if ((form?.iAmInterestedIn ?? 'select') === 'select') {
        setLoading(false);
        toast.error('Please select what you are interested in');
        return;
      }
      if ((form?.addressType ?? 'select') === 'select') {
        setLoading(false);
        toast.error('Please select your address type');
        return;
      }

      content = `<table>
      <tr><th>What would you like to do?</th><td>${
        form.whatWouldYouLikeToDo || ''
      }</td></tr>
      <tr><th>Organisation Name</th><td>${form.organisationName || ''}</td></tr>
      <tr><th>Organisation Suburb</th><td>${
        form.organisationSuburb || ''
      }</td></tr>
      <tr><th>Organisation Type</th><td>${form.organisationType || ''}</td></tr>
      <tr><th>First Name</th><td>${form.firstName || ''}</td></tr>
      <tr><th>Last Name</th><td>${form.lastName || ''}</td></tr>
      <tr><th>Email</th><td>${form.email || ''}</td></tr>
      <tr><th>Phone Number</th><td>${form.phone || ''}</td></tr>
      <tr><th>How did you hear about Expressions?</th><td>${
        (form.howDidYouHear === 'Other'
          ? `Other: ${form.howDidYouHearOther}`
          : form.howDidYouHear) || ''
      }</td></tr>
      <tr><th>I'm interested in?</th><td>${form.iAmInterestedIn || ''}</td></tr>
      <tr><th>Address 1</th><td>${form.address1 || ''}</td></tr>
      <tr><th>Suburb</th><td>${form.suburb || ''}</td></tr>
      <tr><th>State</th><td>${form.state || ''}</td></tr>
      <tr><th>Postcode</th><td>${form.postcode || ''}</td></tr>
      <tr><th>Address Type</th><td>${form.addressType || ''}</td></tr>
      <tr><th>Approximately, how many children at your organisation?</th><td>${
        form.numberOfChildren || ''
      }</td></tr>
      <tr><th>Message</th><td>${form.message || ''}</td></tr>
    </table>`;
    }

    await sendMail({
      to: config.contactEmail,
      email: emails.xrxOt2Eisk3JQ9LlGt8p,
      merge: {
        content,
      },
    });

    setForm({});
    setSubmitted(true);

    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <SEO
        title={'Contact Us'}
        titleTemplate="Expressions Fundraising"
        titleSeparator={`-`}
        description={(siteoptions as any).defaultDescription || ''}
        pathname={`https://www.expressions.com.au/`}
        siteLanguage={'en'}
        siteLocale={'en-AU'}
        twitterUsername={''}
        image={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
      />
      <Dots />
      <div className="relative z-10">
        <h1>Contact Us</h1>

        {!!page?.header_image && (
          <img
            className="w-full mb-4"
            src={getCloudUrl(page?.header_image || '')}
            alt="hero"
          ></img>
        )}

        {!!page?.content.length &&
          page.content.map((c, idx) => {
            return (
              <div key={idx}>
                <Entry entry={c} />
              </div>
            );
          })}

        {submitted && (
          <div className="text-center p-8">
            <p className="text-xl font-bold">Your enquiry has been sent!</p>
            <div>
              <Button
                onClick={() => {
                  setSubmitted(false);
                }}
              >
                Start Again
              </Button>
            </div>
          </div>
        )}

        {!submitted && (
          <>
            <h2 className="mt-6">How can we help?</h2>
            <form onSubmit={submit}>
              <div className="grid grid-cols-1 gap-4">
                <InputLabel htmlFor="whatWouldYouLikeToDo">
                  What would you like to do?
                </InputLabel>
                <Select
                  id="whatWouldYouLikeToDo"
                  defaultValue={form?.whatWouldYouLikeToDo || 'select'}
                  onChange={(e) => {
                    setForm((current: any) => {
                      current.whatWouldYouLikeToDo = e.target.value;
                      return { ...current };
                    });
                  }}
                  required
                >
                  <MenuItem value={'select'}>
                    Please select an option...
                  </MenuItem>
                  <MenuItem value={'Request a brochure and sample'}>
                    Request a brochure and sample
                  </MenuItem>
                  <MenuItem
                    value={"Ready to go and need a coordinator's kit sent out"}
                  >
                    Ready to go and need a coordinator's kit sent out
                  </MenuItem>
                  <MenuItem value={'Request a call back'}>
                    Request a call back
                  </MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>

                {form?.whatWouldYouLikeToDo === 'Request a call back' ? (
                  <>
                    <TextField
                      placeholder="First Name"
                      defaultValue={form?.firstName}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.firstName = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <TextField
                      placeholder="Last Name"
                      defaultValue={form?.lastName}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.lastName = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <InputLabel htmlFor="phone">Phone Number</InputLabel>
                    <TextField
                      id="phone"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+61</InputAdornment>
                        ),
                      }}
                      defaultValue={form?.phone}
                      placeholder="400 123 456"
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.phone = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />
                  </>
                ) : (
                  <></>
                )}

                {form?.whatWouldYouLikeToDo === 'Other' ? (
                  <>
                    <TextField
                      placeholder="First Name"
                      defaultValue={form?.firstName}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.firstName = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <TextField
                      placeholder="Last Name"
                      defaultValue={form?.lastName}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.lastName = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <TextField
                      type="email"
                      placeholder="Email"
                      defaultValue={form?.email}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.email = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <TextField
                      multiline
                      placeholder="Message"
                      defaultValue={form?.message}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.message = e.target.value;

                          return { ...current };
                        });
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}

                {form?.whatWouldYouLikeToDo ===
                  'Request a brochure and sample' ||
                form?.whatWouldYouLikeToDo ===
                  "Ready to go and need a coordinator's kit sent out" ? (
                  <>
                    <TextField
                      placeholder="Organisation Name"
                      defaultValue={form?.organisationName}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.organisationName = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />
                    <TextField
                      placeholder="Organisation Suburb"
                      defaultValue={form?.organisationSuburb}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.organisationSuburb = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <InputLabel htmlFor="organisationType">
                      Organisation Type
                    </InputLabel>
                    <Select
                      id="organisationType"
                      defaultValue={form?.organisationType || 'select'}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.organisationType = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    >
                      <MenuItem value={'select'}>
                        Please select an option...
                      </MenuItem>
                      <MenuItem value={'School'}>School</MenuItem>
                      <MenuItem value={'Pre school or childcare centre'}>
                        Pre school or childcare centre
                      </MenuItem>
                      <MenuItem value={'Retail or merchandise'}>
                        Retail or merchandise
                      </MenuItem>
                      <MenuItem value={'Corporate or promotional'}>
                        Corporate or promotional
                      </MenuItem>
                      <MenuItem value={'Wedding or special occasion'}>
                        Wedding or special occasion
                      </MenuItem>
                      <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>

                    <TextField
                      placeholder="First Name"
                      defaultValue={form?.firstName}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.firstName = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <TextField
                      placeholder="Last Name"
                      defaultValue={form?.lastName}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.lastName = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <TextField
                      type="email"
                      placeholder="Email"
                      defaultValue={form?.email}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.email = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <InputLabel htmlFor="phone">Phone Number</InputLabel>
                    <TextField
                      id="phone"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+61</InputAdornment>
                        ),
                      }}
                      defaultValue={form?.phone}
                      placeholder="400 123 456"
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.phone = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    />

                    <InputLabel htmlFor="howDidYouHear">
                      How did you hear about Expressions?
                    </InputLabel>
                    <Select
                      id="howDidYouHear"
                      defaultValue={form?.howDidYouHear || 'select'}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.howDidYouHear = e.target.value;
                          return { ...current };
                        });
                      }}
                      required
                    >
                      <MenuItem value={'select'}>
                        Please select an option...
                      </MenuItem>
                      <MenuItem value={'Expressions Brochure'}>
                        Expressions Brochure
                      </MenuItem>
                      <MenuItem value={'Google or Internet Search'}>
                        Google or Internet Search
                      </MenuItem>
                      <MenuItem value={'Returning Customer'}>
                        Returning Customer
                      </MenuItem>
                      <MenuItem value={'Fundraising Directory'}>
                        Fundraising Directory
                      </MenuItem>
                      <MenuItem value={'Referral'}>Referral</MenuItem>
                      <MenuItem value={'Facebook'}>Facebook</MenuItem>
                      <MenuItem value={'Instagram'}>Instagram</MenuItem>
                      <MenuItem
                        value={'Saw an Expressions tea towel, bag or apron'}
                      >
                        Saw an Expressions tea towel, bag or apron
                      </MenuItem>
                      <MenuItem value={'Received An Email'}>
                        Received An Email
                      </MenuItem>
                      <MenuItem value={'Received Letter To Our School'}>
                        Received Letter To Our School
                      </MenuItem>
                      <MenuItem value={'P&C'}>
                        P&C
                      </MenuItem>
                      <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                    {form?.howDidYouHear === 'Other' ? (
                      <TextField
                        type="text"
                        placeholder="Where did you hear about us?"
                        defaultValue={form?.howDidYouHearOther}
                        onChange={(e) => {
                          setForm((current: any) => {
                            current.howDidYouHearOther = e.target.value;

                            return { ...current };
                          });
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    <InputLabel htmlFor="iAmInterestedIn">
                      I am interested in
                    </InputLabel>
                    <Select
                      id="iAmInterestedIn"
                      defaultValue={form?.iAmInterestedIn || 'select'}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.iAmInterestedIn = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                    >
                      <MenuItem value={'select'}>
                        Please select an option...
                      </MenuItem>
                      <MenuItem value={'Tea Towels'}>Tea Towels</MenuItem>
                      <MenuItem value={'Tote Bags'}>Tote Bags</MenuItem>
                      <MenuItem value={'Aprons'}>Aprons</MenuItem>
                      <MenuItem value={'Combination of Products'}>
                        Combination of Products
                      </MenuItem>
                    </Select>

                    <TextField
                      type="text"
                      placeholder="Address 1"
                      defaultValue={form?.address1}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.address1 = e.target.value;

                          return { ...current };
                        });
                      }}
                    />
                    <TextField
                      type="text"
                      placeholder="Suburb"
                      defaultValue={form?.suburb}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.suburb = e.target.value;

                          return { ...current };
                        });
                      }}
                    />
                    <TextField
                      type="text"
                      placeholder="State"
                      defaultValue={form?.state}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.state = e.target.value;

                          return { ...current };
                        });
                      }}
                    />
                    <TextField
                      type="number"
                      placeholder="Postcode"
                      defaultValue={form?.postcode}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.postcode = e.target.value;

                          return { ...current };
                        });
                      }}
                    />

                    <InputLabel
                      htmlFor="addressType"
                      style={{
                        textOverflow: 'initial',
                        overflow: 'initial',
                        whiteSpace: 'initial',
                      }}
                    >
                      Is the postal address HOME or ORGANISATION?
                    </InputLabel>
                    <Select
                      id="addressType"
                      defaultValue={form?.addressType || 'select'}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.addressType = e.target.value;

                          return { ...current };
                        });
                      }}
                    >
                      <MenuItem value={'select'}>
                        Please select an option...
                      </MenuItem>
                      <MenuItem value={'Home'}>Home</MenuItem>
                      <MenuItem value={'Organisation'}>Organisation</MenuItem>
                    </Select>

                    <TextField
                      type="number"
                      placeholder="Num of children drawing?"
                      defaultValue={form?.numberOfChildren}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.numberOfChildren = e.target.value;

                          return { ...current };
                        });
                      }}
                      required
                      helperText="Approximately, how many children are drawing?"
                    />

                    <TextField
                      multiline
                      placeholder="Message"
                      defaultValue={form?.message}
                      onChange={(e) => {
                        setForm((current: any) => {
                          current.message = e.target.value;

                          return { ...current };
                        });
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>

              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                className="!mt-4"
              >
                Submit
              </Button>
            </form>

            <h2 className="mt-6">Can I start today?</h2>
            <p>
              If you just can't wait to get started, everything you need is
              available on our{' '}
              <a href="/resources-and-downloads" target="_blank">
                downloads
              </a>{' '}
              page. Look for the{' '}
              <a href="/resources-and-downloads" target="_blank">
                coordinator's guide
              </a>{' '}
              to suit your project and go for it!
            </p>

            <h2 className="mt-6">Get in touch</h2>
            <table
              style={{ color: 'rgb(49, 186, 96)', borderCollapse: 'collapse' }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      padding: '6px 13px',
                      border: '1px solid #d0d7de',
                    }}
                  >
                    Phone:
                  </td>
                  <td
                    style={{
                      padding: '6px 13px',
                      border: '1px solid #d0d7de',
                    }}
                  >
                    <a href="tel:1300 855 509">1300 855 509</a>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: '6px 13px',
                      border: '1px solid #d0d7de',
                    }}
                  >
                    Email:
                  </td>
                  <td
                    style={{
                      padding: '6px 13px',
                      border: '1px solid #d0d7de',
                    }}
                  >
                    <a href="mailto:info@expressions.com.au">
                      info@expressions.com.au
                    </a>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: '6px 13px',
                      border: '1px solid #d0d7de',
                    }}
                  >
                    {' '}
                    Address:
                  </td>
                  <td
                    style={{
                      padding: '6px 13px',
                      border: '1px solid #d0d7de',
                    }}
                  >
                    8 The Centre, Forestville, NSW, 2087
                    <br />
                    PO Box 195, Forestville, NSW, 2087
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </section>
  );
};
