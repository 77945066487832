import { Button } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { config } from '../../util/config';
import { useLocalStorage } from '../../util/localStorage';
import { Dots } from '../Dots';
import { OrderForm } from '../portal/OnlineOrdering';

const firebaseApp = initializeApp(config.firebase);
const db = getFirestore(firebaseApp);
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const EditStore = () => {
  const params = useParams();
  // eslint-disable-next-line
  const [basic, setBasic] = useLocalStorage('expressions_authToken', '');

  const [store, setStore] = useState<any>(null);
  const [settings, setSettings] = useState<any>(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  const refresh = async () => {
    setLoading(true);

    const rs = await fetch(`${config.firebase.functions}/getStore`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Expressions-Auth': basic,
      },
      body: JSON.stringify({}),
    });

    const body = await rs.json();

    setStore(body);

    setLoading(false);
  };

  useEffect(() => {
    getDoc(doc(db, 'siteoptions/default')).then((ref) => {
      if (ref.exists()) {
        setSettings(ref.data());
      }
    });

    refresh().then(() => {});
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    async (form: any) => {
      await fetch(`${config.firebase.functions}/updateStore`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Expressions-Auth': basic,
          Accept: 'application/pdf',
        },
        body: JSON.stringify(form),
      });
    },
    [basic]
  );

  if (!store) {
    return <></>;
  }

  return (
    <section className="options mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>{store.organisationName}'s Store</h1>

        {loading && <div>Loading...</div>}

        <OrderForm
          parentLoading={loading}
          value={store}
          lastOrdersDueDate={settings?.lastOrdersDueDate || null}
          onSubmit={async (form) => {
            toast.promise(onSubmit(form), {
              loading: 'Saving store...',
              success: 'Store saved',
              error: 'An error occured',
            });
          }}
        />

        <Button
          onClick={(e) => {
            e.preventDefault();

            window.location.href = `/fundraising-schools/${params.id}/manage`;
          }}
        >
          Cancel
        </Button>
      </div>
    </section>
  );
};
