import { Markdown } from '@camberi/firecms';
import { getCloudUrl } from '../../util/image';

export const HowItWorksText = ({ value }: { value: any }) => {
  return (
    <>
      <div
        className={
          value.indented
            ? 'flex flex-row w-full justify-start items-start ml-6 how-it-works-row'
            : 'flex flex-row w-full justify-start items-start -ml-6 how-it-works-row'
        }
      >
        <div>
          <span
            className="text-8xl px-4 font-extrabold grow-0"
            style={{ color: value.titleColour }}
          >
            {value.number}
          </span>
        </div>
        <div className="pt-6 grow">
          <h3 className="mb-0" style={{ color: value.titleColour }}>
            {value.title}
          </h3>
          <Markdown source={value.text} />
        </div>
        {value.image ? (
          <div className=" w-40 hidden lg:block shrink-0">
            <div className="rounded-full border border-white h-40 min-h-40 max-h-40 aspect-square overflow-hidden object-cover absolute">
              <img
                src={getCloudUrl(value.image)}
                alt="how it works"
                className="scale-125"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
