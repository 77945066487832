import { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

import { Button, MenuItem } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridCsvExportMenuItem,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router';
import { emails } from '../../cms/emails';
import { Base64 } from '../../util/base64';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { logDebug } from '../../util/log';

const db = getFirestore();
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const StoreOrders = () => {
  const params = useParams();
  const location = useLocation();
  const [products, setProducts] = useState<any[]>([]);
  const [basic, setBasic] = useState<string>('');
  const [orders, setOrders] = useState<any[]>([]);
  const [stats, setStats] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [initialState] = useState(
    location.search.includes('pending')
      ? {
          filter: {
            filterModel: {
              items: [
                {
                  columnField: 'status',
                  operatorValue: 'equals',
                  value: 'pending',
                },
              ],
            },
          },
        }
      : {}
  );

  const columns = [
    { field: 'orderId', headerName: 'Order Id', flex: 1 },
    { field: 'status', headerName: 'Status' },
    { field: 'description', headerName: 'Product', flex: 1 },
    { field: 'qty', type: 'number', headerName: 'Qty' },
    { field: 'classroom', headerName: 'Classroom', flex: 1 },
    {
      field: 'parentName',
      headerName: 'Parent',
      flex: 1,
    },
    { field: 'childName', headerName: 'Child', flex: 1 },
    { field: 'designStyle', headerName: 'Design Style' },
    {
      field: 'gross',
      type: 'number',
      headerName: 'Gross',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `$${valueFormatted}`;
      },
    },
    {
      field: 'credit',
      type: 'number',
      headerName: 'Credit',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `$${valueFormatted}`;
      },
    },
    {
      field: 'donation',
      type: 'number',
      headerName: 'Donation',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `$${valueFormatted}`;
      },
    },
    {
      field: 'total',
      type: 'number',
      headerName: 'Total',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `$${valueFormatted}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (rowParams: GridRowParams) => [
        <GridActionsCellItem
          showInMenu
          disabled={rowParams.row.status !== 'confirmed'}
          hidden={rowParams.row.status === 'pending'}
          onClick={async (e) => {
            e.preventDefault();

            const orderId = rowParams.row.orderId;
            const idx = rowParams.row.id.split('_')[1];

            confirmAlert({
              title: 'Update Product',
              message: 'Select a new product to replace the current product.',
              buttons: products.map((p) => ({
                label: p.description,
                onClick: async () => {
                  const currentOrder = await getDoc(
                    doc(db, `stores/${params.id}/orders/${orderId}`)
                  ).then((doc) => doc.data());

                  if (!currentOrder?.cart) {
                    toast.error('Cart invalid');
                    return;
                  }

                  const cart = { ...currentOrder.cart };
                  console.log(cart);
                  cart.products[idx].id = p.id;

                  await updateDoc(
                    doc(db, `stores/${params.id}/orders/${orderId}`),
                    {
                      cart,
                    }
                  );

                  toast.success('Product updated');

                  await refresh();
                },
              })),
            });
          }}
          label="Change Product"
        />,
        <GridActionsCellItem
          showInMenu
          disabled={rowParams.row.status !== 'confirmed'}
          hidden={rowParams.row.status === 'pending'}
          onClick={async (e) => {
            e.preventDefault();

            const orderId = rowParams.row.orderId;
            const idx = rowParams.row.id.split('_')[1];

            var newQty = parseInt(
              prompt('Enter new quantity', rowParams.row.qty) ?? ''
            );
            if (isNaN(newQty)) {
              toast.error('Qty invalid');
              return;
            }

            const currentOrder = await getDoc(
              doc(db, `stores/${params.id}/orders/${orderId}`)
            ).then((doc) => doc.data());

            if (!currentOrder?.cart) {
              toast.error('Cart invalid');
              return;
            }

            const cart = { ...currentOrder.cart };
            console.log(cart);
            cart.products[idx].qty = newQty;

            await updateDoc(doc(db, `stores/${params.id}/orders/${orderId}`), {
              cart,
            });

            toast.success('Product updated');

            await refresh();
          }}
          label="Update Qty"
        />,
        <GridActionsCellItem
          showInMenu
          disabled={rowParams.row.status !== 'confirmed'}
          hidden={rowParams.row.status === 'pending'}
          onClick={async (e) => {
            e.preventDefault();

            await invoice(rowParams.row);
          }}
          label="Invoice"
        />,
        <GridActionsCellItem
          showInMenu
          disabled={rowParams.row.status !== 'confirmed'}
          hidden={rowParams.row.status === 'pending'}
          onClick={async (e) => {
            e.preventDefault();

            confirmAlert({
              title: 'Cancel Order',
              message:
                'Are you sure you wish to cancel this order. The payment transaction will be refunded?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: async () => {
                    await refund(rowParams.row);
                  },
                },
                {
                  label: 'No',
                  onClick: () => {},
                },
              ],
            });
          }}
          label="Cancel"
        />,
        <GridActionsCellItem
          showInMenu
          disabled={rowParams.row.status !== 'pending'}
          hidden={rowParams.row.status !== 'pending'}
          onClick={async (e) => {
            e.preventDefault();

            const status = await checkOrder(rowParams.row).catch((err) =>
              toast.error(err.message || 'Error checking order')
            );
            logDebug(`order status ${status}`);

            if (status === 'succeeded') {
              toast.success('Payment confirmed');
            } else {
              toast.success(`Payment status ${status}`);
            }
          }}
          label="Check Payment"
        />,
        <GridActionsCellItem
          showInMenu
          disabled={rowParams.row.status !== 'pending'}
          hidden={rowParams.row.status !== 'pending'}
          onClick={async (e) => {
            e.preventDefault();

            confirmAlert({
              title: 'Delete Order',
              message:
                'Are you sure you wish to delete this order? This cannot be undone.',
              buttons: [
                {
                  label: 'Yes',
                  onClick: async () => {
                    await deleteOrder(rowParams.row).catch((err) =>
                      toast.error(err.message || 'Error deleting order')
                    );
                  },
                },
                {
                  label: 'No',
                  onClick: () => {},
                },
              ],
            });
          }}
          label="Delete"
        />,
      ],
    },
  ];

  const refresh = useCallback(async () => {
    setLoading(true);

    const docRef = await getDoc(doc(db, `stores/${params.id}`));
    if (docRef.exists()) {
      const store = docRef.data();
      const combined = `${params.id}:${store.password}`;
      const _basic = Base64.encode(combined);
      setBasic(_basic);
      setProducts(store.products);

      if (_basic) {
        const orderRs = await fetch(`${config.firebase.functions}/getOrders`, {
          method: 'POST', // or 'PUT'
          headers: {
            'Expressions-Auth': _basic,
          },
          body: JSON.stringify({}),
        });

        const _orders = await orderRs.json();

        const items: any = {};

        for (const o of _orders) {
          if (o.status === 'confirmed') {
            if (!items[o.description]) {
              items[o.description] = o.qty;
            } else {
              items[o.description] += o.qty;
            }
          }
        }

        setOrders(_orders);
        setStats(items);
        setLoading(false);
      }
    }
  }, [params.id]);

  const refund = useCallback(
    async (order: any) => {
      await fetch(`${config.firebase.functions}/refund`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Expressions-Auth': basic,
        },
        body: JSON.stringify({ orderId: order.id.split('_')[0] }),
      });

      await sendMail({
        to: order.email,
        email: emails.Tz4TMZpRtzajX08i9fCs,
        merge: {
          id: order.orderId,
          parentFirstName: order.parentFirstName,
        },
      });

      await refresh();
    },
    [basic, refresh]
  );

  const deleteOrder = useCallback(
    async (order: any) => {
      await fetch(`${config.firebase.functions}/deleteOrder`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Expressions-Auth': basic,
        },
        body: JSON.stringify({ orderId: order.orderId }),
      });

      await refresh();
    },
    [basic, refresh]
  );

  const checkOrder = useCallback(
    async (order: any) => {
      const rs = await fetch(
        `${config.firebase.functions}/checkPaymentIntent`,
        {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
            'Expressions-Auth': basic,
          },
          body: JSON.stringify({
            orderId: order.orderId,
            payment_intent: order.pendingTxnId,
            mode: order.mode,
          }),
        }
      );

      await refresh();

      return await rs.text();
    },
    [basic, refresh]
  );

  const invoice = useCallback(
    async (order: any) => {
      const inv = await fetch(`${config.firebase.functions}/invoice`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Expressions-Auth': basic,
          Accept: 'application/pdf',
        },
        body: JSON.stringify({ orderId: order.orderId }),
      });

      const blob = await inv.blob();

      saveAs(blob, `${order.orderId}.pdf`);
    },
    [basic]
  );

  const excel = useCallback(async () => {
    const inv = await fetch(`${config.firebase.functions}/reportxls`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      body: JSON.stringify({}),
    });

    const blob = await inv.blob();

    saveAs(blob, `${params.id}-${format(new Date(), 'yyyyLLdd')}.xlsx`);
  }, [basic, params.id]);

  const pdf = useCallback(async () => {
    const inv = await fetch(`${config.firebase.functions}/report`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept: 'application/pdf',
      },
      body: JSON.stringify({}),
    });

    const blob = await inv.blob();

    saveAs(blob, `${params.id}-${format(new Date(), 'yyyyLLdd')}.pdf`);
  }, [basic, params.id]);

  useEffect(() => {
    refresh().then(() => {});
    // eslint-disable-next-line
  }, []);

  const GridToolbarExport = () => (
    <GridToolbarExportContainer>
      <GridCsvExportMenuItem />
      <MenuItem
        onClick={async (e) => {
          e.preventDefault();

          await pdf();
        }}
      >
        Download PDF Report
      </MenuItem>
      <MenuItem
        onClick={async (e) => {
          e.preventDefault();

          await excel();
        }}
      >
        Download Excel Report
      </MenuItem>
    </GridToolbarExportContainer>
  );

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const num_stats = stats ? Object.keys(stats).length : 0;
  // full - header - header-padd - page-padd - heading
  const height = num_stats
    ? `calc(100vh - 64px - 2rem - 5rem - 5rem - 5rem - 2rem - ${
        num_stats * 1.5
      }rem`
    : `calc(100vh - 64px - 2rem - 5rem - 5rem)`;

  const refreshPending = useCallback(async () => {
    setLoading(true);

    try {
      for (const order of orders) {
        await fetch(`${config.firebase.functions}/checkPaymentIntent`, {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
            'Expressions-Auth': basic,
          },
          body: JSON.stringify({
            orderId: order.orderId,
            payment_intent: order.pendingTxnId,
            mode: order.mode,
          }),
        }).catch(() => {});
      }

      await refresh();
    } finally {
      setLoading(false);
    }
  }, [orders, basic, refresh]);

  return (
    <section className="options mx-6 my-6 relative">
      <div className="relative z-10">
        <div style={{ padding: '1rem' }}>
          <h1>Orders</h1>

          <div style={{ minHeight: '400px', height: height, width: '100%' }}>
            <DataGrid
              initialState={initialState}
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: { printOptions: { disableToolbarButton: true } },
              }}
              columns={columns}
              rows={orders}
              loading={loading}
              getRowClassName={(params: any) => {
                if (params.row.refunded) {
                  return 'strike';
                }
                if (params.row.status === 'pending') {
                  return 'pending';
                }
                return '';
              }}
            />
          </div>

          {orders?.filter((o) => o.status === 'pending')?.length ? (
            <>
              <h1 className="mt-6">Pending Orders</h1>
              <Button type="button" onClick={refreshPending} disabled={loading}>
                Check all pending orders
              </Button>
            </>
          ) : (
            <></>
          )}

          {!!stats && (
            <>
              <h1 className="mt-6">Stats</h1>
              <div className="shadow-md rounded-lg border-2 p-4">
                {Object.keys(stats).map((key: string, index: number) => (
                  <div key={index} className="flex justify-between">
                    <div className="text-gray-400">{key}</div>
                    <div>{stats[key]}</div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
