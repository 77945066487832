import { config } from '../../util/config';
import { Dots } from '../Dots';

export const Version = () => {
  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>Version</h1>

        <p>{config.version}</p>
      </div>
    </section>
  );
};
