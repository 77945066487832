import {
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Snackbar,
  TextField,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { config } from '../../util/config';

const db = getFirestore();
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const Options = () => {
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState('');
  const [form, setForm] = useState<any>(null);

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await setDoc(doc(db, `siteoptions/default`), form)
      .catch((err) => setSnack(err))
      .finally(() => setLoading(false));

    setSnack('Settings saved');
  };
  useEffect(() => {
    getDoc(doc(db, 'siteoptions/default')).then((ref) => {
      if (ref.exists()) {
        setForm(ref.data());
      }
    });
  }, []);

  return (
    <section className="p-6">
      <h1>Site Options</h1>

      {!!form && (
        <form onSubmit={submit}>
          <div className="grid grid-cols-1 gap-4">
            <TextField
              multiline
              placeholder="Order Form Special Message"
              rows="4"
              defaultValue={form?.specialMessage}
              onChange={(e) => {
                setForm((current: any) => {
                  current.specialMessage = e.target.value;
                  return current;
                });
              }}
              helperText="Include any info you would like to show at the top of the order form."
            />

            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setForm((current: any) => {
                      current.disableRegistration = e.target.checked;
                      return current;
                    });
                  }}
                  defaultChecked={form.disableRegistration}
                />
              }
              label="Disable Online Store Order Form"
            />

            <InputLabel htmlFor="disabledFrom">
              Auto-Disable Form Between
            </InputLabel>

            <div style={{ display: 'flex' }}>
              <TextField
                id="disabledFrom"
                type="date"
                defaultValue={form?.disabledFrom}
                onChange={(e) => {
                  setForm((current: any) => {
                    try {
                      current.disabledFrom = format(
                        parseISO(e.target.value),
                        'yyyy-MM-dd'
                      );
                    } catch {
                      current.disabledFrom = null;
                    }
                    return current;
                  });
                }}
              />
              <TextField
                id="disabledTo"
                type="date"
                defaultValue={form?.disabledTo}
                onChange={(e) => {
                  setForm((current: any) => {
                    try {
                      current.disabledTo = format(
                        parseISO(e.target.value),
                        'yyyy-MM-dd'
                      );
                    } catch {
                      current.disabledTo = null;
                    }
                    return current;
                  });
                }}
              />
            </div>

            <TextField
              multiline
              placeholder="Order Form Disabled Message"
              rows="4"
              defaultValue={form?.disabledMessage}
              onChange={(e) => {
                setForm((current: any) => {
                  current.disabledMessage = e.target.value;
                  return current;
                });
              }}
              required={form?.disableRegistration}
              helperText="This text will be displayed in place of the order form when disabled."
            />

            <InputLabel htmlFor="lastOrdersDueDate">
              Last Orders Due Date
            </InputLabel>

            <div style={{ display: 'flex' }}>
              <TextField
                id="lastOrdersDueDate"
                type="date"
                defaultValue={form?.lastOrdersDueDate}
                onChange={(e) => {
                  setForm((current: any) => {
                    try {
                      current.lastOrdersDueDate = format(
                        parseISO(e.target.value),
                        'yyyy-MM-dd'
                      );
                    } catch {
                      current.lastOrdersDueDate = null;
                    }
                    return current;
                  });
                }}
              />
            </div>
          </div>

          <h2 className="mt-6">SEO</h2>
          <div className="grid grid-cols-1 gap-4">
            <TextField
              multiline
              placeholder="Default Description"
              rows="4"
              defaultValue={form?.defaultDescription}
              onChange={(e) => {
                setForm((current: any) => {
                  current.defaultDescription = e.target.value;
                  return current;
                });
              }}
              helperText="Default page description."
            />
          </div>

          <h2 className="mt-6">Site-Wide Notice Banner</h2>
          <div className="grid grid-cols-1 gap-4">
            <TextField
              multiline
              placeholder="Site-Wide Notice"
              rows="4"
              defaultValue={form?.siteWideNotice}
              onChange={(e) => {
                setForm((current: any) => {
                  current.siteWideNotice = e.target.value;
                  return current;
                });
              }}
              helperText="Site-Wide Notice Banner."
            />
          </div>
          <div className="grid grid-cols-1 gap-4">
            <TextField
              multiline
              placeholder="Site-Wide Notice Link"
              rows="4"
              defaultValue={form?.siteWideNoticeLink}
              onChange={(e) => {
                setForm((current: any) => {
                  current.siteWideNoticeLink = e.target.value;
                  return current;
                });
              }}
              helperText="Site-Wide Notice Banner Link."
            />
          </div>

          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading}
            className="!mt-4"
          >
            Submit
          </Button>
        </form>
      )}
      <Snackbar
        open={!!snack}
        onClose={() => {
          setSnack('');
        }}
        autoHideDuration={3000}
        message={snack}
      />
    </section>
  );
};
