import { buildProperty, buildSchema } from '@camberi/firecms';

export type Product = {
  order: number;
  name: string;
  rrp: number;
  colours: string[];
  printColours: string[];
  printType: string;
};

export const productSchema = buildSchema<Product>({
  name: 'Product',
  properties: {
    order: {
      title: 'Order',
      validation: {
        required: true,
      },
      dataType: 'number',
    },
    name: {
      title: 'Name',
      validation: { required: true },
      dataType: 'string',
    },
    rrp: {
      title: 'RRP',
      validation: {
        required: true,
        requiredMessage: 'You must set a price between 0 and 1000',
        min: 0,
        max: 1000,
      },
      description: 'Recommended retail price',
      dataType: 'number',
    },
    colours: buildProperty({
      title: 'Item Colours',
      description: 'Item colour options',
      dataType: 'array',
      of: buildProperty({
        dataType: 'string',
        title: 'Text',
      }),
    }),
    printType: buildProperty(({ values }) => ({
      title: 'Print Type',
      validation: { required: true },
      dataType: 'string',
      columnWidth: 140,
      config: {
        enumValues: {
          screen: {
            label: 'Screen Print',
          },
          digital: {
            label: 'Digital Print',
          },
          individual: {
            label: 'Individual Designs',
          },
        },
      },
    })),
    printColours: ({ values }) =>
      buildProperty({
        title: 'Print Colours',
        description: 'Screen print colour options',
        dataType: 'array',
        of: buildProperty({
          dataType: 'string',
          title: 'Text',
        }),
        disabled:
          values.printType === 'screen'
            ? false
            : {
                clearOnDisabled: true,
                disabledMessage:
                  'Print Type must be Screen Print in order to enable this colour',
              },
      }),
  },
});
