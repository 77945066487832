import { Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
  GridToolbar,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { format, parse } from 'date-fns';
import { saveAs } from 'file-saver';
import {
  collection,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { emails } from '../../cms/emails';
import { Base64 } from '../../util/base64';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';

const db = getFirestore();
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const Stores = () => {
  const [snack, setSnack] = useState('');
  const [stores, setStores] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const excel = async (id: string, password: string) => {
    const combined = `${id}:${password}`;
    const basic = Base64.encode(combined);

    const inv = await fetch(`${config.firebase.functions}/reportxls`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      body: JSON.stringify({}),
    });

    const blob = await inv.blob();

    saveAs(blob, `${id}-${format(new Date(), 'yyyyLLdd')}.xlsx`);
  };

  const pdf = async (id: string, password: string) => {
    const combined = `${id}:${password}`;
    const basic = Base64.encode(combined);

    const inv = await fetch(`${config.firebase.functions}/report`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept: 'application/pdf',
      },
      body: JSON.stringify({}),
    });

    const blob = await inv.blob();

    saveAs(blob, `${id}-${format(new Date(), 'yyyyLLdd')}.pdf`);
  };

  const columns = [
    { field: 'id', headerName: 'Store Id', flex: 1 },
    {
      field: 'created',
      type: 'date',
      headerName: 'Created',
    },
    { field: 'status', headerName: 'Status' },
    { field: 'organisationName', headerName: 'Organisation', flex: 1 },
    {
      field: 'firstName',
      headerName: 'Contact',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => {
        return `${params.row.firstName} ${params.row.lastName}`;
      },
    },
    { field: 'lastName', headerName: 'Last Name', hide: true },
    { field: 'phone', headerName: 'Phone', flex: 0.75 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'campaignEndDate',
      type: 'date',
      headerName: 'Final Orders Due Date',
    },
    {
      field: 'products',
      type: 'number',
      headerName: '# Products',
      valueFormatter: (params: GridValueFormatterParams<any[]>) => {
        return params.value.length || 0;
      },
      renderCell: (params: GridRenderCellParams<number>) => {
        return params.row.products.length || 0;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          showInMenu
          onClick={async (e) => {
            e.preventDefault();
            window.location.href = `/admin/stores/${params.row.id}`;
          }}
          label="View"
        />,
        <GridActionsCellItem
          showInMenu
          onClick={async (e) => {
            e.preventDefault();
            window.location.href = `/admin/stores/${params.row.id}/orders`;
          }}
          label="Orders"
        />,
        <GridActionsCellItem
          showInMenu
          onClick={async (e) => {
            e.preventDefault();
            window.location.href = `/admin/stores/${params.row.id}/orders?pending`;
          }}
          label="Pending Orders"
        />,
        <GridActionsCellItem
          showInMenu
          onClick={async (e) => {
            e.preventDefault();

            await pdf(params.row.id, params.row.password);
          }}
          label="Download PDF Report"
        />,
        <GridActionsCellItem
          showInMenu
          onClick={async (e) => {
            e.preventDefault();

            await excel(params.row.id, params.row.password);
          }}
          label="Download Excel Report"
        />,
        ...(params.row.status === 'requested'
          ? [
              <GridActionsCellItem
                showInMenu
                onClick={async (e) => {
                  e.preventDefault();

                  confirmAlert({
                    title: 'Delete Store',
                    message: 'Are you sure you wish to delete this store?',
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: async () => {
                          await deleteDoc(
                            doc(db, `public-stores/${params.row.id}`)
                          ).catch(() => {});
                          await deleteDoc(
                            doc(db, `stores/${params.row.id}`)
                          ).catch(() => {});
                        },
                      },
                      {
                        label: 'No',
                        onClick: () => {},
                      },
                    ],
                  });
                }}
                label="Delete"
              />,
            ]
          : []),
        ...(params.row.status !== 'requested'
          ? [
              <GridActionsCellItem
                showInMenu
                onClick={async (e) => {
                  e.preventDefault();

                  await toast.promise(
                    sendMail({
                      to: params.row.email,
                      email: emails.ZO705KbT6O2Qax2hvAPZ,
                      merge: {
                        url: `${window.location.protocol}//${window.location.host}/fundraising-schools/${params.row.id}/manage`,
                        organisationName: params.row.organisationName,
                      },
                    }),
                    {
                      loading: 'Sending email',
                      success: 'Email sent',
                      error: (err) => err.message,
                    }
                  );
                }}
                label="Send Store Closed Email"
              ></GridActionsCellItem>,
            ]
          : []),
        <GridActionsCellItem
          showInMenu
          onClick={async (e) => {
            e.preventDefault();

            if (
              window.confirm('Are you sure you wish to archive this store?')
            ) {
              await updateDoc(doc(db, `stores/${params.row.id}`), {
                status: 'archived',
              }).catch(() => {});
            }
          }}
          label="Archive"
        ></GridActionsCellItem>,
      ],
    },
  ];

  const refresh = async () => {
    setLoading(true);

    const q = query(collection(db, 'stores'), orderBy('created', 'desc'));
    const querySnapshot = await getDocs(q);

    const result: any[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      data.created =
        typeof data.created.toDate === 'function'
          ? data.created.toDate()
          : new Date(data.created._seconds * 1000);
      if (data.campaignEndDate) {
        data.campaignEndDate = parse(
          data.campaignEndDate,
          'yyyy-LL-dd',
          new Date()
        );
      }
      result.push(data);
    });

    setStores(result);

    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div className="p-6">
      <div
        style={{
          minHeight: '400px',
          height: 'calc(100vh - 64px - 2rem - 3rem)',
          width: '100%',
        }}
      >
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
          }}
          columns={columns}
          rows={stores}
          loading={loading}
          getRowClassName={(params: any) => {
            if (params.row.refunded) {
              return 'strike';
            }
            if (params.row.status === 'pending') {
              return 'pending';
            }
            return '';
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: 'status',
                    operatorValue: 'isAnyOf',
                    value: [
                      'active',
                      'requested',
                      'approved',
                      'published',
                      'closed',
                    ],
                  },
                ],
              },
            },
          }}
        />
      </div>

      <Snackbar
        open={!!snack}
        onClose={() => {
          setSnack('');
        }}
        autoHideDuration={3000}
        message={snack}
      />
    </div>
  );
};
