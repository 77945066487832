import { buildProperty, buildSchema } from '@camberi/firecms';
export type Testimonial = {
  order: number;
  name: string;
  organisation: string;
  comment: string;
  image: string;
};

export const testimonialSchema = buildSchema<Testimonial>({
  name: 'Testimonial',
  properties: {
    order: {
      title: 'Order',
      dataType: 'number',
      validation: { required: true },
    },
    name: {
      title: 'Name',
      dataType: 'string',
      columnWidth: 300,
      validation: { required: true },
    },
    organisation: {
      title: 'Organisation',
      dataType: 'string',
      columnWidth: 300,
      validation: { required: true },
    },
    comment: {
      title: 'Comment',
      description: 'Testimonial content',
      dataType: 'string',
      validation: { required: true },
    },
    image: ({ values }) =>
      buildProperty({
        title: 'Image',
        dataType: 'string',
        validation: { required: true },
        config: {
          storageMeta: {
            mediaType: 'image',
            storagePath: 'images',
            acceptedFiles: ['image/*'],
          },
        },
      }),
  },
});
