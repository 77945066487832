import { Dots } from '../Dots';

export const PageNotFound = () => {
  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>Page Not Found</h1>
        <p>
          The page you requested does not exist. Click <a href="/">here</a> to
          continue browsing.
        </p>
      </div>
    </section>
  );
};
