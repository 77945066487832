export const config = {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
    functions: process.env.REACT_APP_FIREBASE_FUNCTIONS,
  },
  social: {
    facebook: 'https://www.facebook.com/ExpressionsAustralia',
    instagram: 'https://www.instagram.com/expressionsaustralia/',
  },
  gitlab: {
    id: process.env.REACT_APP_GITLAB_ID as string,
    ref: process.env.REACT_APP_GITLAB_REF as string,
    trigger: process.env.REACT_APP_GITLAB_TRIGGER as string,
  },
  paypal: {
    clientId: process.env.REACT_APP_PAYPAL_CLIENTID,
  },
  stripe: {
    mode: process.env.REACT_APP_STRIPE_MODE as string,
    publishableKey: process.env.REACT_APP_STRIPE_PUB as string,
  },
  logtail: process.env.REACT_APP_LOGTAIL as string,
  env: process.env.REACT_APP_ENV as string,
  adminEmail: 'onlineorders@expressions.com.au',
  contactEmail: 'info@expressions.com.au',
  surcharge: 0.035,
  setupFee: 25,
  credit: 0,
  version: '1.0.2',
};
