import { Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { emails } from '../../cms/emails';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { OrderForm } from '../portal/OnlineOrdering';

const db = getFirestore();
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const EditStore = () => {
  const params = useParams();

  const [store, setStore] = useState<any>(null);
  const dialog = useRef(null);
  const [loading, setLoading] = useState(true);

  const refresh = async () => {
    setLoading(true);
    const querySnapshot = await getDoc(doc(db, `stores/${params.id}`));
    setStore({ ...querySnapshot.data(), id: querySnapshot.id });
    setLoading(false);
  };

  const publicInfo = (s: any): any => {
    if (s?.products?.length) {
      for (const p of s.products) {
        p.price = parseFloat(p.price);
        if (!!p.bundlePrice) {
          p.bundlePrice = parseFloat(p.bundlePrice);
        }
      }
    }

    return {
      id: s.id,
      organisationName: s.organisationName,
      campaignEndDate: s.campaignEndDate,
      products: s.products,
      bundlePrice: s.bundlePrice || null,
      specialMessage: s.specialMessage || null,
      status: s.status,
    };
  };

  const activate = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    if (!store.password) {
      store.password = generatePassword();
    }

    const active = store.status !== 'active';

    if (active) {
      await updateDoc(doc(db, `stores/${store.id}`), {
        status: 'active',
        password: store.password,
      });
      await setDoc(
        doc(db, `public-stores/${store.id}`),
        publicInfo({ ...store, status: 'active' })
      );

      await sendMail({
        to: store.email,
        email: emails.HHKLzatywmHV3xgJM1t0,
        merge: {
          url: `${window.location.protocol}//${window.location.host}/fundraising-schools/${store.id}`,
          password: store.password,
        },
      });

      toast.success('This store has been activated');
    } else {
      await updateDoc(doc(db, `stores/${store.id}`), { status: 'requested' });
      await setDoc(
        doc(db, `public-stores/${store.id}`),
        publicInfo({ ...store, status: 'requested' })
      );

      toast.success('This store has been deactivated');
    }

    await refresh();

    if (dialog.current) {
      (dialog.current as any).close();
    }
  };

  const resetPassword = async () => {
    confirmAlert({
      title: 'Reset Password',
      message: 'Are you sure you wish to reset the store password?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            store.password = generatePassword();

            const active = store.status === 'active';

            if (active) {
              await updateDoc(doc(db, `stores/${store.id}`), {
                status: 'active',
                password: store.password,
              });
              await setDoc(
                doc(db, `public-stores/${store.id}`),
                publicInfo({ ...store, status: 'active' })
              );

              await sendMail({
                to: store.email,
                email: emails.HHKLzatywmHV3xgJM1t0,
                merge: {
                  url: `${window.location.protocol}//${window.location.host}/fundraising-schools/${store.id}`,
                  password: store.password,
                },
              });
            } else {
              await setDoc(
                doc(db, `public-stores/${store.id}`),
                publicInfo({ ...store, status: 'requested' })
              );
            }

            await refresh();

            if (dialog.current) {
              (dialog.current as any).close();
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const closeStore = async (e: any) => {
    e.preventDefault();

    // const confirmText = store?.shippingCode
    //   ? 'Are you sure you wish to close this store?'
    //   : 'You have not entered a discount shipping code, are you sure you wish to close this store?';
    const confirmText =
      'Are you sure you wish to approve order numbers and close this store?';

    confirmAlert({
      title: 'Close Store',
      message: confirmText,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await deleteDoc(doc(db, `public-stores/${store.id}`));
            await updateDoc(doc(db, `stores/${store.id}`), {
              status: 'approved',
            });

            await sendMail({
              to: config.adminEmail,
              email: emails['1Nr15wh8KrTtinTQ2hnF'],
              merge: {
                organisationName: store.organisationName,
                url: `${window.location.protocol}//${window.location.host}/admin/stores/${store.id}/orders`,
              },
            });

            await refresh();

            if (dialog.current) {
              (dialog.current as any).close();
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  const generatePassword = () => {
    var randomstring = Math.random().toString(36).slice(-8);
    return randomstring;
  };

  if (!store) {
    return <></>;
  }

  const onSubmit = async (form: any) => {
    if (form.status === 'active' && !form.password) {
      form.password = generatePassword();
    }

    await updateDoc(doc(db, `stores/${form.id}`), form);
    await setDoc(doc(db, `public-stores/${form.id}`), publicInfo(form));
  };

  return (
    <section className="p-6">
      <h1>{store.organisationName}'s Store</h1>

      <OrderForm
        parentLoading={loading}
        admin
        value={store}
        onSubmit={async (form) => {
          toast.promise(onSubmit(form), {
            loading: 'Saving store...',
            success: 'Store saved',
            error: 'An error occured',
          });
        }}
        resetPassword={resetPassword}
      />
      <div className="flex">
        {store?.status !== 'closed' && (
          <Button disabled={loading} onClick={activate}>
            {store?.status === 'active' ? 'Deactivate Store' : 'Activate Store'}
          </Button>
        )}
        {store?.status === 'active' && (
          <Button disabled={loading} onClick={closeStore}>
            Approve Orders &amp; Close Store
          </Button>
        )}
        <Button
          onClick={() => {
            window.location.href = `/admin/stores`;
          }}
        >
          Back
        </Button>
      </div>
    </section>
  );
};
