import { useEffect, useState } from 'react';

import { MenuItem } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridCsvExportMenuItem,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { emails } from '../../cms/emails';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { useLocalStorage } from '../../util/localStorage';
import { Dots } from '../Dots';

export const OrderReport = () => {
  const [basic] = useLocalStorage('expressions_authToken', '');
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    { field: 'orderId', headerName: 'Order Id', flex: 1 },
    { field: 'status', headerName: 'Status' },
    { field: 'description', headerName: 'Product', flex: 1 },
    { field: 'qty', type: 'number', headerName: 'Qty' },
    { field: 'classroom', headerName: 'Classroom', flex: 1 },
    {
      field: 'parentName',
      headerName: 'Parent',
      flex: 1,
    },
    { field: 'childName', headerName: 'Child', flex: 1 },
    { field: 'designStyle', headerName: 'Design Style' },
    {
      field: 'gross',
      type: 'number',
      headerName: 'Gross',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `$${valueFormatted}`;
      },
    },
    {
      field: 'credit',
      type: 'number',
      headerName: 'Credit',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `$${valueFormatted}`;
      },
    },
    {
      field: 'donation',
      type: 'number',
      headerName: 'Donation',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `$${valueFormatted}`;
      },
    },
    {
      field: 'total',
      type: 'number',
      headerName: 'Total',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `$${valueFormatted}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          showInMenu
          disabled={params.row.status !== 'confirmed'}
          hidden={params.row.status === 'pending'}
          onClick={async (e) => {
            e.preventDefault();

            await invoice(params.row);
          }}
          label="Invoice"
        />,
        <GridActionsCellItem
          showInMenu
          disabled={params.row.status !== 'confirmed'}
          onClick={async (e) => {
            e.preventDefault();

            confirmAlert({
              title: 'Cancel Order',
              message:
                'Are you sure you wish to cancel this order. The payment transaction will be refunded?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: async () => {
                    await refund(params.row);
                  },
                },
                {
                  label: 'No',
                  onClick: () => {},
                },
              ],
            });
          }}
          label="Cancel"
        />,
      ],
    },
  ];

  const refresh = async () => {
    setLoading(true);

    if (basic) {
      const orderRs = await fetch(`${config.firebase.functions}/getOrders`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Expressions-Auth': basic,
        },
        body: JSON.stringify({}),
      });

      const _orders = await orderRs
        .json()
        .then((oo) => oo.filter((o: any) => o.status !== 'pending'));

      setOrders(_orders);
      setLoading(false);
    }
  };

  const refund = async (order: any) => {
    await fetch(`${config.firebase.functions}/refund`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
      },
      body: JSON.stringify({ orderId: order.orderId }),
    });

    await sendMail({
      to: order.email,
      email: emails.Tz4TMZpRtzajX08i9fCs,
      merge: {
        id: order.orderId,
        parentFirstName: order.parentFirstName,
      },
    });

    await refresh();
  };

  const invoice = async (order: any) => {
    const inv = await fetch(`${config.firebase.functions}/invoice`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept: 'application/pdf',
      },
      body: JSON.stringify({ orderId: order.orderId }),
    });

    const blob = await inv.blob();

    saveAs(blob, `${order.orderId}.pdf`);
  };

  const excel = async () => {
    const inv = await fetch(`${config.firebase.functions}/reportxls`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      body: JSON.stringify({}),
    });

    const blob = await inv.blob();

    saveAs(blob, `report-${format(new Date(), 'yyyyLLdd')}.xlsx`);
  };
  const pdf = async () => {
    const inv = await fetch(`${config.firebase.functions}/report`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept: 'application/pdf',
      },
      body: JSON.stringify({}),
    });

    const blob = await inv.blob();

    saveAs(blob, `report-${format(new Date(), 'yyyyLLdd')}.pdf`);
  };

  useEffect(() => {
    refresh().then(() => {});
    // eslint-disable-next-line
  }, []);

  const GridToolbarExport = () => (
    <GridToolbarExportContainer>
      <GridCsvExportMenuItem />
      <MenuItem
        onClick={async (e) => {
          e.preventDefault();

          await pdf();
        }}
      >
        Download PDF Report
      </MenuItem>
      <MenuItem
        onClick={async (e) => {
          e.preventDefault();

          await excel();
        }}
      >
        Download Excel Report
      </MenuItem>
    </GridToolbarExportContainer>
  );

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <section className="options mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <div style={{ padding: '1rem' }}>
          <h1>Orders</h1>

          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: { printOptions: { disableToolbarButton: true } },
              }}
              columns={columns}
              rows={orders}
              loading={loading}
              getRowClassName={(params: any) => {
                if (params.row.refunded) {
                  return 'strike';
                }
                if (params.row.status === 'pending') {
                  return 'pending';
                }
                return '';
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
