import { initializeApp } from 'firebase/app';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { config } from './config';
const mjml2html = require('mjml-browser');

const app = initializeApp(config.firebase);
const functions = getFunctions(app);
if (config.env === 'dev') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const sendMail = async (data: {
  to: string;
  email: {
    mjml: string;
    subject: string;
  };
  merge: any;
  invoice?: any;
}) => {
  const subject = (window as any).Handlebars.compile(data.email.subject)(
    data.merge
  );
  const mjml = (window as any).Handlebars.compile(data.email.mjml)(data.merge);

  const html = mjml2html(mjml).html;

  const fun = httpsCallable<
    {
      to: string;
      subject: string;
      html: string;
      invoice: any;
    },
    boolean
  >(functions, 'sendMail');
  return await fun({
    to: data.to,
    subject,
    html,
    invoice: data.invoice,
  });
};
