import { Button, InputAdornment, InputLabel, TextField } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {
  Timestamp,
  addDoc,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import {
  connectStorageEmulator,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import JSZip from 'jszip';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SEO from 'react-seo-component';
import { emails } from '../../cms/emails';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { logError } from '../../util/log';
import { Dots } from '../Dots';

const firebaseApp = initializeApp(config.firebase);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
}

export const ArtForm = ({
  value,
  onSubmit,
  parentLoading,
}: {
  value?: any;
  onSubmit: (form: any, images: File[]) => void;
  parentLoading: boolean;
}) => {
  const [images, setImages] = useState<File[]>([]);
  const [form, setForm] = useState<any>(value || {});
  const [loading, setLoading] = useState(false);

  const validate = (): string => {
    if (!form?.firstName) {
      return 'First Name required';
    }
    if (!form?.lastName) {
      return 'Last Name required';
    }
    if (!form?.organisationName) {
      return 'Organisation name required';
    }
    if (!form?.email) {
      return 'Email Address required';
    }
    if (!form?.phone) {
      return 'Phone Number required';
    }
    if (!form?.description) {
      return 'Description required';
    }
    if (!images?.length) {
      return 'At least one image is required';
    }

    return '';
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const errMsg = validate();
    if (errMsg) {
      toast.error(errMsg);
      setLoading(false);
      return;
    }

    try {
      await onSubmit(form, images);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submit}>
      <div className="grid grid-cols-1 gap-4">
        <TextField
          placeholder="First Name"
          defaultValue={form?.firstName}
          onChange={(e) => {
            setForm((current: any) => {
              current.firstName = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          placeholder="Last Name"
          defaultValue={form?.lastName}
          onChange={(e) => {
            setForm((current: any) => {
              current.lastName = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          placeholder="Organisation Name"
          defaultValue={form?.organisationName}
          onChange={(e) => {
            setForm((current: any) => {
              current.organisationName = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          placeholder="ID Number"
          helperText="if known"
          defaultValue={form?.idNumber}
          onChange={(e) => {
            setForm((current: any) => {
              current.idNumber = e.target.value;
              return current;
            });
          }}
        />

        <TextField
          type="email"
          placeholder="Email"
          defaultValue={form?.email}
          onChange={(e) => {
            setForm((current: any) => {
              current.email = e.target.value;
              return current;
            });
          }}
          required
        />

        <InputLabel htmlFor="phone">Mobile Phone</InputLabel>
        <TextField
          id="phone"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+61</InputAdornment>
            ),
          }}
          defaultValue={form?.phone}
          placeholder="400 123 456"
          onChange={(e) => {
            setForm((current: any) => {
              current.phone = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          multiline
          placeholder="Description"
          rows="4"
          defaultValue={form?.description}
          onChange={(e) => {
            setForm((current: any) => {
              current.description = e.target.value;
              return current;
            });
          }}
          helperText="Please provide a description of what you are sending us - eg changes, submitting all artwork etc."
        />

        <InputLabel htmlFor="fileUpload">Files</InputLabel>
        <input
          id="fileUpload"
          type="file"
          multiple
          accept="image/jpg,image/jpeg"
          onChange={(e) => {
            let hasInvalid = false;

            setImages((current) => {
              const ret = [...current];
              if (e.target.files?.length) {
                for (let i = 0; i < e.target.files.length; i++) {
                  const file = e.target.files.item(i);
                  if (
                    file &&
                    ['image/jpg', 'image/jpeg'].includes(
                      file.type.toLowerCase()
                    )
                  ) {
                    ret.push(file);
                  } else {
                    hasInvalid = true;
                  }
                }
              }
              return ret;
            });

            if (hasInvalid) {
              toast.error(
                'One or more of the files you selected was not supported. Please only upload JPG files.'
              );
            }
          }}
        />
        <p
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
          id="mui-6-helper-text"
        >
          Minimum 150dpi for black & white, 300dpi for colour. Submit all files
          at once.
        </p>
        {images?.length ? (
          images.map((i, idx) => (
            <div key={idx}>
              {i.name} | {i.size}B{' '}
              <span
                style={{
                  color: 'red',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setImages((current) => {
                    return current.filter((f) => f.name !== i.name);
                  });
                }}
              >
                remove
              </span>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>

      <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={loading || parentLoading}
        className="!mt-4"
      >
        Submit
      </Button>
    </form>
  );
};

export const UploadArtwork = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<any>(null);

  const submit = async (form: any, images: File[]) => {
    setLoading(true);

    try {
      const rs = await addDoc(collection(db, 'artwork'), {
        ...form,
        created: Timestamp.now(),
      });

      const jszip = new JSZip();

      for (const file of images) {
        jszip.file(file.name, file);
      }

      const zip = await jszip.generateAsync({ type: 'blob' });

      const fileRef = ref(storage, `artwork/${rs.id}.zip`);

      await uploadBytes(fileRef, zip);

      await sendMail({
        to: config.contactEmail,
        email: emails.fxBZU9qtj0CQBjtU5u8l,
        merge: {
          name: form.organisationName,
          url: `${window.location.protocol}//${window.location.host}/admin/artwork`,
        },
      });

      setSubmitted(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDoc(doc(db, 'siteoptions/default')).then((ref) => {
      if (ref.exists()) {
        setSettings(ref.data());
      }
    });
  }, []);

  // const disableForm = `We're closed for ordering for 2023 - for any urgent queries please contact us at <a href="mailto:info@expressions.com.au">info@expressions.com.au</a>`;
  const disableForm = '';

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <SEO
        title={'Artwork Upload'}
        titleTemplate="Expressions Fundraising"
        titleSeparator={`-`}
        description={
          'Expressions Australia Artwork Upload for school, childcare, daycare, club and charity fundraising.'
        }
        pathname={`https://www.expressions.com.au/`}
        siteLanguage={'en'}
        siteLocale={'en-AU'}
        twitterUsername={''}
        image={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
      />
      <Dots />
      <div className="relative z-10">
        <h1>Upload Artwork</h1>
        {disableForm ? (
          <></>
        ) : (
          <>
            <p>
              Please submit <strong>ALL</strong> images, logo and order details
              form as jpeg files at one time (minimum 150dpi for black & white,
              300dpi for colour). Please double check when scanning that your
              images are not stretched or overlapping. If you have a large
              number of drawings, please contact our office and we will provide
              a link for you.
            </p>
            <p>
              Note that scanning can be time consuming with varied results. We
              ask that you consider posting your drawings for us to process as
              it may be faster and easier for you.
            </p>
            <p>
              <strong>Send to:</strong> Expressions PO Box 195 Forestville NSW
              2087
            </p>
            <p>
              <strong>Note:</strong> if you require alphabetical order, images{' '}
              <strong>MUST</strong> be supplied this way
            </p>
          </>
        )}
        {!!settings?.specialMessage ? (
          <div className="!my-8 border-2 border-solid border-green-500 p-4">
            <p className="!m-0 relative pl-6">
              <span className="absolute -ml-4 text-xl font-bold text-green-500">
                !
              </span>{' '}
              {settings?.specialMessage}
            </p>
          </div>
        ) : (
          <></>
        )}

        {disableForm ? (
          <div className="!my-8 border-2 border-solid border-green-500 p-4">
            <p className="!m-0 relative pl-6">
              <span className="absolute -ml-4 text-xl font-bold text-green-500">
                !
              </span>{' '}
              <span dangerouslySetInnerHTML={{ __html: disableForm }}></span>
            </p>
          </div>
        ) : (
          <>
            {submitted ? (
              <>
                <p>Thank you for uploading your artwork.</p>

                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className="!mt-4"
                  onClick={(e) => {
                    e.preventDefault();
                    setSubmitted(false);
                  }}
                >
                  Upload More Artwork
                </Button>
              </>
            ) : (
              <ArtForm
                onSubmit={async (form, images) => {
                  toast.promise(submit(form, images), {
                    loading: 'Submitting form...',
                    success: 'Your upload has been submitted',
                    error: (ex) => {
                      logError(ex);
                      return `An error occured - ${ex.message}`;
                    },
                  });
                }}
                parentLoading={loading}
              ></ArtForm>
            )}
          </>
        )}
      </div>
    </section>
  );
};
