import { format } from 'date-fns';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import SEO from 'react-seo-component';
import { news } from '../../cms/news';
import { siteoptions } from '../../cms/siteoptions';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';
import { Entry } from '../Entry';

export const NewsArticle = () => {
  const params = useParams();
  const article: any = params.slug
    ? (news as any)[params.slug as string]
    : null;

  const newsArray: any[] = Object.values(news).sort(
    (a: any, b: any) => b?.created_at?._seconds - a?.created_at?._seconds
  );

  const index = newsArray.indexOf(article);

  const upcoming =
    index < newsArray.length - 1 ? newsArray.splice(index + 1, 3) : [];

  useEffect(() => {
    if (!article) {
      window.location.href = '/404';
    }
  }, [article]);

  return (
    <article className="page mx-6 md:mx-36 my-16 relative">
      <SEO
        title={article?.title || article?.name || ''}
        titleTemplate="Expressions Fundraising"
        titleSeparator={`-`}
        description={
          article?.description || (siteoptions as any).defaultDescription || ''
        }
        pathname={`https://www.expressions.com.au/${article?.slug}`}
        siteLanguage={'en'}
        siteLocale={'en-AU'}
        twitterUsername={''}
        image={
          article?.header_image
            ? getCloudUrl(article.header_image)
            : `https://www.expressions.com.au/Expressions_Logo_Final.jpg`
        }
      />

      <Dots />
      {article ? (
        <div className="relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            {article.header_image ? (
              <div
                className="bg-cover bg-center w-full h-[30rem] col-span-2"
                style={{
                  backgroundImage: `url(${getCloudUrl(article.header_image)})`,
                }}
              >
                {!article.header_image_seo && (
                  <img
                    style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
                    src={`url(${getCloudUrl(article.header_image)})`}
                    alt={article.header_image_seo ?? 'article image'}
                  />
                )}
              </div>
            ) : (
              <div></div>
            )}
            {upcoming.length ? (
              <div className="flex-col gap-4 hidden lg:flex">
                <h3>Read More</h3>
                {upcoming.map((u, idx) => (
                  <a key={idx} href={`/news/${u.slug}`}>
                    <div className="shadow grid grid-cols-3">
                      <div
                        className="h-full bg-cover bg-center"
                        style={{
                          backgroundImage: `url(${getCloudUrl(
                            u.header_image
                          )})`,
                        }}
                      >
                        {!!u.header_image_seo && (
                          <img
                            style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
                            src={`url(${getCloudUrl(u.header_image)})`}
                            alt={u.header_image_seo ?? 'article image'}
                          />
                        )}
                      </div>
                      <div className="p-4 col-span-2">
                        <div>{u.name}</div>
                        <div className="font-bold">{u.author}</div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <h1 className="mt-8">{article.name}</h1>
          <div>
            By <strong>{article.author}</strong> on{' '}
            <strong>
              {format(
                new Date(article.created_at._seconds * 1000),
                'MMMM, dd yyyy'
              )}
            </strong>
          </div>

          <div className="mt-8">
            {!!article.content.length &&
              (article.content as any[]).filter((c) => !!c).map((c, idx) => {
                return (
                  <div key={idx}>
                    <Entry entry={c} />
                  </div>
                );
              })}
          </div>

          {upcoming.length ? (
            <div className="flex-col gap-4 flex mt-8 lg:hidden">
              <h3>Read More</h3>
              {upcoming.map((u, idx) => (
                <a key={idx} href={`/news/${u.slug}`}>
                  <div className="shadow grid grid-cols-3">
                    <div
                      className="h-full bg-cover bg-center"
                      style={{
                        backgroundImage: `url(${getCloudUrl(u.header_image)})`,
                      }}
                    >
                      {!!u.header_image_seo && (
                        <img
                          style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
                          src={`url(${getCloudUrl(u.header_image)})`}
                          alt={u.header_image_seo ?? 'article image'}
                        />
                      )}
                    </div>
                    <div className="p-4 col-span-2">
                      <div>{u.name}</div>
                      <div className="font-bold">{u.author}</div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <></>
      )}
    </article>
  );
};
