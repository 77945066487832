import { buildProperty, buildSchema } from '@camberi/firecms';

export type ImageUrl = {
  order: number;
  image: string;
  url?: string;
};

export const imageUrlSchema = buildSchema<ImageUrl>({
  name: 'ImageUrl',
  properties: {
    order: {
      title: 'Order',
      dataType: 'number',
      validation: { required: true },
    },
    image: buildProperty({
      title: 'Image',
      dataType: 'string',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'images',
          acceptedFiles: ['image/*'],
        },
      },
    }),
    url: {
      title: 'URL',
      description: 'Optional link',
      dataType: 'string',
      columnWidth: 300,
    },
  },
});
