import {
  Authenticator,
  CMSView,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from '@camberi/firecms';
import { initializeApp } from 'firebase/app';
import { User as FirebaseUser } from 'firebase/auth';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import {
  connectStorageEmulator,
  getStorage,
  listAll,
  ref,
} from 'firebase/storage';
import 'typeface-rubik';
import 'typeface-space-mono';
import logo from '../assets/logo.svg';
import { config } from '../util/config';
import { logDebug } from '../util/log';
import { Artwork } from './cms/Artwork';
import { EditStore } from './cms/EditStore';
import { Options } from './cms/Options';
import { Publish } from './cms/Publish';
import { collections } from './cms/schema';
import { StoreOrders } from './cms/StoreOrders';
import { Stores } from './cms/Stores';
import { Uploads } from './cms/Uploads';
import { Users } from './cms/Users';

export const AdminRouter = () => {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    // get files
    const storage = getStorage();
    if (config.env === 'dev') {
      connectStorageEmulator(storage, 'localhost', 9199);
    }
    const uploadRef = ref(storage, 'uploads');

    const files = await listAll(uploadRef);

    const nav = collections(authController, files.items);
    nav.views = [
      {
        path: 'options',
        group: 'Admin',
        name: 'Site Options',
        description: 'Various site options',
        // This can be any React component
        view: <Options></Options>,
      } as CMSView,
      {
        path: 'publish',
        group: 'Admin',
        name: 'Publish',
        description: 'Publish the latest version of your website from here',
        // This can be any React component
        view: <Publish></Publish>,
      } as CMSView,
      {
        path: 'uploads',
        group: 'Admin',
        name: 'Uploads',
        description: 'Manage uploads from here',
        view: <Uploads></Uploads>,
      } as CMSView,
      {
        path: 'artwork',
        group: 'Admin',
        name: 'Artwork',
        description: 'Manage artwork uploads from here',
        view: <Artwork></Artwork>,
      } as CMSView,
      {
        path: 'users',
        group: 'Admin',
        name: 'Users',
        description: 'Manage users from here',
        view: <Users></Users>,
      } as CMSView,
      {
        path: 'stores',
        group: 'Store',
        name: 'Stores',
        description: 'Online stores that are active or have been requested',
        view: <Stores></Stores>,
      } as CMSView,
      {
        path: 'stores/:id',
        group: 'Store',
        name: 'Stores',
        description: 'Online stores that are active or have been requested',
        view: <EditStore></EditStore>,
        hideFromNavigation: true,
      } as CMSView,
      {
        path: 'stores/:id/orders',
        group: 'Store',
        name: 'Orders',
        description: 'Orders for each online store',
        view: <StoreOrders></StoreOrders>,
        hideFromNavigation: true,
      } as CMSView,
    ];
    return nav;
  };

  const firebaseApp = initializeApp(config.firebase);

  const db = getFirestore(firebaseApp);
  if (config.env === 'dev') {
    connectFirestoreEmulator(db, 'localhost', 8080);
  }

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
  }) => {
    logDebug(`Allowing access to ${user?.email}`);

    const dbUser = (await getDoc(doc(db, `/users/${user?.uid}`))).data();
    if (dbUser) {
      const sampleUserData = {
        roles: [dbUser.role],
      };
      authController.setExtra(sampleUserData);
    } else {
      throw Error('Invalid username/password!');
    }

    return true;
  };

  return (
    <>
      <FirebaseCMSApp
        basePath="admin"
        name={'Expressions Fundraising'}
        logo={logo}
        authentication={myAuthenticator}
        navigation={navigation}
        firebaseConfig={config.firebase}
        signInOptions={['password']}
      />
    </>
  );
};
