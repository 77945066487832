import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useState } from 'react';
import SEO from 'react-seo-component';
import { siteoptions } from '../../cms/siteoptions';
import { config } from '../../util/config';
import { Dots } from '../Dots';

export const PayOnline = () => {
  const [token, setToken] = useState('');

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <SEO
        title={'Expressions Fundraising School Pay Online'}
        titleTemplate="Expressions Fundraising"
        titleSeparator={`-`}
        description={(siteoptions as any).defaultDescription || ''}
        pathname={`https://www.expressions.com.au/`}
        siteLanguage={'en'}
        siteLocale={'en-AU'}
        twitterUsername={''}
        image={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
      />

      <Dots />
      <div className="relative z-10">
        <h1>Pay Online</h1>

        <p>Thank you for choosing to pay online.</p>
        <p>
          Click below and you will be redirected to a secure NAB credit card
          payment window. Please have exact amount and invoice number ready.
        </p>
        <p>
          <form
            target="_blank"
            action={`${config.firebase.functions}/processCaptcha`}
            method="POST"
          >
            <HCaptcha
              sitekey="e60f15c5-7f0e-4ddf-a01d-e5e23181d157"
              onVerify={setToken}
            />

            <input name="token" value={token} type="hidden" />

            <button
              disabled={!token}
              style={{ opacity: token ? 1 : 0.5 }}
              type="submit"
              className="button"
            >
              Pay Now
            </button>
          </form>
        </p>
      </div>
    </section>
  );
};
