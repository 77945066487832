import { buildProperty, buildSchema, EntitySchema } from '@camberi/firecms';
import { StorageReference } from 'firebase/storage';
import { BlogEntryPreview } from '../BlogEntryPreview';

export type VideoEntry = {
  url: string;
};

export const getVideosSchema = (
  files: StorageReference[]
): EntitySchema<any> => {
  return buildSchema({
    name: 'YouTube video',
    views: [
      {
        path: 'preview',
        name: 'Preview',
        builder: (props) => <BlogEntryPreview {...props} />,
      },
    ],
    properties: {
      order: buildProperty({
        title: 'Order',
        validation: { required: true },
        dataType: 'number',
      }),
      title: buildProperty({
        title: 'Title',
        validation: { required: true },
        dataType: 'string',
      }),
      url: buildProperty({
        title: 'Url',
        validation: { required: true },
        dataType: 'string',
      }),
    },
    defaultValues: {
      status: 'draft',
    },
  });
};
