import lunr from 'lunr';
import { faq } from '../cms/faq';
import { news } from '../cms/news';
import { pages } from '../cms/pages';

export const searchIndex = lunr(function () {
  this.field('title');
  this.field('body');

  for (const f of faq) {
    const id = f.question.replace(/[^a-zA-Z0-9]/g, '-');

    for (let text of f.answer.filter((a) => a.type === 'text' && a.value)) {
      this.add({
        title: f.question,
        body: text.value
          .replace(/<a.+?<\/a>/g, '')
          .replace(/\[.+?]\(.+?\)/g, ''),
        id: `faq_${id}`,
      });
    }
  }

  for (const f in pages) {
    const page = pages[f as keyof typeof pages];
    if (page.status !== 'published' || !(page as any).content) continue;

    for (let text of (page as any).content.filter(
      (a: any) => a.type === 'text' && a.value
    )) {
      this.add({
        title: page.title,
        body: text.value
          .replace(/<a.+?<\/a>/g, '')
          .replace(/\[.+?]\(.+?\)/g, ''),
        id: `page_${page.slug}`,
      });
    }
  }

  for (const f in news) {
    const page = news[f as keyof typeof news];
    if (page.status !== 'published' || !(page as any).content) continue;

    for (let text of (page as any).content.filter(
      (a: any) => a.type === 'text' && a.value
    )) {
      this.add({
        title: page.title,
        body: text.value
          .replace(/<a.+?<\/a>/g, '')
          .replace(/\[.+?]\(.+?\)/g, ''),
        id: `news_${page.slug}`,
      });
    }
  }
});
