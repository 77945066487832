import { AuthController, buildCollection, Navigation } from '@camberi/firecms';
import { StorageReference } from 'firebase/storage';
import { getBlogSchema } from './schema/blog';
import { emailSchema } from './schema/email';
import { faqSchema } from './schema/faq';
import { imageUrlSchema } from './schema/image';
import { getNewsSchema } from './schema/news';
import { productSchema } from './schema/product';
import { sectionSchema } from './schema/section';
import { testimonialSchema } from './schema/testimonial';
import { getVideosSchema } from './schema/videos';

export const collections = (
  authController: AuthController<any>,
  files: StorageReference[]
): Navigation => {
  return {
    collections: [
      buildCollection({
        path: 'home-sections',
        group: 'Home Page',
        schema: sectionSchema,
        name: 'Home Page Sections',
        description: 'Home page content sections',
        initialSort: ['order', 'asc'],
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'slider',
        group: 'Home Page',
        schema: sectionSchema,
        name: 'Home Page Slider',
        description: 'Images for the home page slider component',
        initialSort: ['order', 'asc'],
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'options',
        group: 'Home Page',
        schema: sectionSchema,
        name: 'Fundraising Options',
        description: 'Fundraising option section',
        initialSort: ['order', 'asc'],
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'testimonials',
        group: 'Content',
        schema: testimonialSchema,
        name: 'Testimonials',
        description: 'Testimonials for the home page, and testimonial page',
        initialSort: ['order', 'asc'],
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'awards',
        group: 'Home Page',
        schema: imageUrlSchema,
        name: 'Awards',
        description: 'Award images',
        initialSort: ['order', 'asc'],
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'media',
        group: 'Home Page',
        schema: imageUrlSchema,
        name: 'Media',
        description: 'Media images',
        initialSort: ['order', 'asc'],
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'faq',
        group: 'Content',
        schema: faqSchema,
        name: 'FAQs',
        description: 'Frequently asked questions and answers for the FAQs page',
        initialSort: ['order', 'asc'],
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'pages',
        group: 'Pages',
        schema: getBlogSchema(files),
        name: 'Pages',
        description: 'Individual pages',
        callbacks: {
          onPreSave: ({ schema, path, entityId, values, status }) => {
            if (!values.slug) {
              values.slug = (values.name || '')
                .toLowerCase()
                .replace(/[ ?'"]/g, '-');
            }
            return values;
          },
        },
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'videos',
        group: 'Home Page',
        schema: getVideosSchema(files),
        name: 'Videos',
        description: 'Home page YouTube videos',
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'news',
        group: 'Pages',
        schema: getNewsSchema(files),
        name: 'News',
        description: 'News articles',
        initialSort: ['created_at', 'desc'],
        callbacks: {
          onPreSave: ({ schema, path, entityId, values, status }) => {
            if (!values.slug) {
              values.slug = (values.name || '')
                .toLowerCase()
                .replace(/[ ?'"]/g, '-');
            }
            return values;
          },
        },
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
      buildCollection({
        path: 'emails',
        group: 'Emails',
        schema: emailSchema,
        name: 'Emails',
        description: 'Email message editor for all transactional emails',
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: false,
        }),
      }),
      buildCollection({
        path: 'products',
        group: 'Store',
        schema: productSchema,
        name: 'Products',
        initialSort: ['order', 'asc'],
        description: 'Products for online stores',
        permissions: ({ authController }) => ({
          edit: authController.extra.roles.includes('admin'),
          create: authController.extra.roles.includes('admin'),
          delete: authController.extra.roles.includes('admin'),
        }),
      }),
    ],
  };
};
