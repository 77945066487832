import { useEffect } from 'react';
import SEO from 'react-seo-component';
import { siteoptions } from '../../cms/siteoptions';
import { Dots } from '../Dots';

export const ClearCache = () => {
  useEffect(() => {
    if (localStorage.getItem('expressions_cart')) {
      localStorage.removeItem('expressions_cart');
    }
    if (localStorage.getItem('expressions_authToken')) {
      localStorage.removeItem('expressions_authToken');
    }
  }, []);

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <SEO
        title={'Clear Cache'}
        titleTemplate="Expressions Fundraising"
        titleSeparator={`-`}
        description={(siteoptions as any).defaultDescription || ''}
        pathname={`https://www.expressions.com.au/`}
        siteLanguage={'en'}
        siteLocale={'en-AU'}
        twitterUsername={''}
        image={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
      />
      <Dots />
      <div className="relative z-10">
        <h1>Clear Cache</h1>
        <p>Your cache has been cleared.</p>
      </div>
    </section>
  );
};
