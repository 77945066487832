import { buildSchema } from '@camberi/firecms';
import { MjmlPreview } from '../MjmlPreview';

export type Email = {
  code: string;
  subject: string;
  mjml: string;
};

export const emailSchema = buildSchema<Email>({
  name: 'Email',
  views: [
    {
      path: 'preview',
      name: 'Preview',
      builder: (props) => <MjmlPreview {...props} />,
    },
  ],
  properties: {
    code: {
      title: 'Name',
      validation: { required: true },
      dataType: 'string',
    },
    subject: {
      title: 'Subject',
      validation: { required: true },
      dataType: 'string',
    },
    mjml: {
      title: 'MJML',
      validation: { required: true },
      dataType: 'string',
      config: {
        multiline: true,
      },
    },
  },
});
