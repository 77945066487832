import { EntityCustomViewParams, useStorageSource } from '@camberi/firecms';
import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { Entry } from '../Entry';

/**
 * This is a sample view used to render the content of a page.
 * It is bound to the data that is modified in the form.
 * @constructor
 */
export function BlogEntryPreview({ modifiedValues }: EntityCustomViewParams) {
  const storage = useStorageSource();

  const [headerUrl, setHeaderUrl] = useState<string | undefined>();
  useEffect(() => {
    if (modifiedValues?.header_image) {
      storage
        .getDownloadURL(modifiedValues.header_image)
        .then((res) => setHeaderUrl(res));
    }
  }, [storage, modifiedValues?.header_image]);

  return (
    <Box>
      {headerUrl && (
        <img
          alt={modifiedValues?.header_image_seo ?? 'hero image'}
          style={{
            width: '100%',
            maxHeight: '300px',
            objectFit: 'cover',
          }}
          src={headerUrl}
        />
      )}

      <Container
        maxWidth={'md'}
        style={{
          alignItems: 'center',
          justifyItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* {modifiedValues?.name && (
          <Typography
            variant={'h2'}
            style={{
              marginTop: '40px',
              marginBottom: '20px',
            }}
          >
            {modifiedValues.name}
          </Typography>
        )} */}

        {modifiedValues?.content &&
          modifiedValues.content
            .filter((e: any) => !!e)
            .map((entry: any, index: number) => {
              return <Entry key={index} entry={entry} />;
            })}
      </Container>
    </Box>
  );
}

export function StorageImage({ storagePath }: { storagePath: string }) {
  const storage = useStorageSource();
  const [url, setUrl] = useState<string | undefined>();
  useEffect(() => {
    if (storagePath) {
      storage.getDownloadURL(storagePath).then((res) => setUrl(res));
    }
  }, [storage, storagePath]);

  if (!storagePath) return <></>;

  return (
    <img
      alt={'Generic'}
      style={{
        objectFit: 'contain',
        width: '100%',
        height: '100%',
      }}
      src={url}
    />
  );
}
