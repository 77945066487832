import { useEffect, useRef, useState } from 'react';
import { Dots } from '../Dots';

export const ProfitSlider = () => {
  const scaledCostPrice = [
    { start: 40, end: 69, price: 13.5 },
    { start: 70, end: 99, price: 11.5 },
    { start: 100, end: 149, price: 10.5 },
    { start: 150, end: 199, price: 9.5 },
    { start: 200, end: 299, price: 9 },
    { start: 300, end: 1000, price: 8.5 },
  ];

  const [priceInputValue, setPriceInputValue] = useState('200'); //for user-directed price-input
  const [costPrice, setCostPrice] = useState(9); //for cost price

  const slider = useRef(null); //for slider itself
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {}, []); // [] - runs only on the 1st render

  const [unitPrice, setUnitPrice] = useState('20.00'); //set user-directed unit price (in input box)
  // to handle unit price (in input box)
  const handleUnitPrice = (e: any) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (isNaN(e.target.value)) {
        e.target.value = 1;
        return;
      }

      setUnitPrice(e.target.value);
    }, 500);
  };

  const handlePricingSlide = (e: any) => {
    setPriceInputValue(e.target.value);
    if (!isNaN(e.target.value)) {
      const cp =
        scaledCostPrice.find(
          (c) =>
            c.start <= parseInt(e.target.value) &&
            parseInt(e.target.value) <= c.end
        )?.price ?? 0;
      setCostPrice(cp);
    }
  };

  return (
    <>
      <Dots />
      <div className="relative z-10">
        <h1>Estimated Profit</h1>
        <h2>Sell More, Make More</h2>
        <p>
          Adjust your sale price and slide bar to see how much you can earn.
        </p>
        <div>
          {/* Price Input (Via Slider)*/}
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '2rem',
                marginTop: '3rem',
              }}
            >
              <label style={{ width: '100%' }}>
                <input
                  type="range"
                  ref={slider}
                  defaultValue={priceInputValue}
                  onChange={handlePricingSlide}
                  min="50"
                  max="1000"
                  style={{ width: '100%' }}
                />
              </label>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                Sell {priceInputValue} tea towels and raise{' '}
                <strong style={{ fontSize: '2.5rem' }}>
                  $
                  {(
                    parseInt(priceInputValue) * parseFloat(unitPrice) -
                    parseInt(priceInputValue) * costPrice
                  ).toFixed(2)}
                </strong>
              </div>
              <div className="pricing-slider-text">
                Buy <strong>{priceInputValue}</strong> tea towels @ $
                <strong>{costPrice.toFixed(2)}</strong> and sell them at{' '}
                <input
                  type="text"
                  inputMode="decimal"
                  style={{
                    border: 'solid 1px black',
                    padding: '4px',
                    width: '4rem',
                  }}
                  defaultValue={unitPrice}
                  onChange={handleUnitPrice}
                />{' '}
                each.
              </div>
              <div className="pricing-slider-text">
                Based on basic pricing (no bundles), 1 colour screen print, per
                unique design.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
